import { signOut, fetchAuthSession } from 'aws-amplify/auth';
import { toast } from 'react-toastify';

let navigate, store;
export const setNavigate = (nav, str) => {
    navigate = nav;
    store = str;
};

export const handleLogout = async () => {
    try {
      await signOut();
      localStorage.removeItem('user_id');
      navigate('/');
      store.dispatch({ type: 'LOGOUT' });
    } catch (error) {
      console.error('Logout error:', error);
      localStorage.removeItem('user_id');
      for (let key in localStorage) {
        if (key.startsWith('Cognito')) {
          localStorage.removeItem(key);
        }
      }
    }
  };

export const fetchAuthSessionWithLogout = async () => {
  try {
    const { accessToken } = (await fetchAuthSession()).tokens ?? {};
    if (!accessToken) {
      const sessionExpired = localStorage.getItem('session_expired');
      if (sessionExpired && sessionExpired !== 'true') {
        toast.error('Your session has expired. Please sign in again.');
        localStorage.setItem('session_expired', 'true');
        await handleLogout();
      }
      return;
    }
    return accessToken;
  } catch (error) {
    console.error('Error fetching auth session:', error);
    await handleLogout();
  }
};
