/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { useWavesurfer } from '@wavesurfer/react';
import RecordPlugin from 'wavesurfer.js/dist/plugins/record.esm.js';
import Playback from './Playback';
import { Button } from '@/components/shadcn-ui/button';
import { Decoder, tools, Reader } from 'ts-ebml';
import { Buffer } from 'buffer';
import Loader from './Loader';
import { PlayIcon, RotateCcw } from 'lucide-react';
import PauseIcon from '../images/SVG/pauseIcon.svg'
import MicIcon from '../images/SVG/playIcon.svg'
import doneIcon from '../images/SVG/doneIcon.svg'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/shadcn-ui/alert-dialog"
import { useDispatch, useSelector } from 'react-redux';
import { selectRecordingDuration, selectReportId, setRecordingDuration, setRecordingStarted, setReportId } from '@/redux/ReportsSlice';
import { createRecordingLog } from '@/utils/HelperFunctions';
import { v4 as uuidv4 } from "uuid";
import { isIOS } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
window.Buffer = Buffer;

const formatTime = (numberOfSeconds) => {
  let date = new Date(0);
  date.setSeconds(numberOfSeconds);
  return date.toISOString().substring(11, 19);
};

let pauseCount = 0; // on each pause an extra chunk added to audioChunksRef
let audioContext = null;
const playbackDuration = 60; // 1 minute
const maxDuration = 3 * 60 * 60; // 3 hours
const createdObjectURLs = [];

const Recorder = ({ setMetaType, handleAudio, handlePauseRecording, setRecordingTime, setHidingtabs, autoStart = false }) => {
  const { t } = useTranslation();
  const containerRef = useRef(null);
  const audioChunksRef = useRef([]);
  const firstChunks = useRef([]);
  const audioUrl = useRef(null);
  const [durationProgress, setDurationProgress] = useState(0);
  const reportId = useSelector(selectReportId);
  const [active, setActive] = useState(autoStart);
  const [startRecording, setStartRecording] = useState(autoStart);
  const duration = useSelector(selectRecordingDuration);
  const [finalRecording, setFinalRecording] = useState(null);
  const [playBackState, setPlayBackState] = useState('pause');
  const [isDisabled, setIsDisabled] = useState(true);
  const restartStatusRef = useRef(false);
  const [playback, setPlayback] = useState(false);
  const [processingUrl, setProcessingUrl] = useState(false);
  const dispatch = useDispatch();

  // Initialize AudioContext only once
  useEffect(() => {
    if (!audioContext) {
      audioContext = new (window.AudioContext || window.webkitAudioContext)();
    }
    
    // Cleanup function to properly close AudioContext and revoke URLs
    return () => {
      if (audioContext) {
        audioContext.close().catch(err => console.error("Error closing AudioContext:", err));
        audioContext = null;
      }
      
      // Revoke all created object URLs
      createdObjectURLs.forEach(url => {
        URL.revokeObjectURL(url);
      });
    };
  }, []);

  const { wavesurfer } = useWavesurfer({
    container: containerRef,
    height: 60,
    waveColor: '#535A63',
    progressColor: '#EE772F',
    barHeight: 1,
    barWidth: 2,
    barRadius: 4,
    barGap: 4,
  });

  const recordPluginInstance = useMemo(() => {
    if (wavesurfer) {
      const pluginOptions = {
        scrollingWaveform: true,
        renderRecordedAudio: false,
        audioBitsPerSecond: 8000,
        mediaRecorderTimeslice: 1000, // call onDataAvailable every second
        ...(isIOS && { mimeType: 'audio/mp4' })
      };

      return wavesurfer?.registerPlugin(RecordPlugin.create(pluginOptions));
    }
    return null
  }, [wavesurfer]);

  const isPaused = playback && recordPluginInstance && (recordPluginInstance.isPaused() || !active)

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        if (recordPluginInstance && active && !recordPluginInstance.isPaused()) {
          handlePause();
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [recordPluginInstance, active]);

  useEffect(() => {
    pauseCount = 0
  }, [])

  useEffect(() => {
    if (finalRecording && window.isRecordingDone) {
      window.isRecordingDone = false;
      handleAudio(finalRecording);
    }
    else {
      setFinalRecording(null);
    }
  },
    // eslint-disable-next-line
    [finalRecording, window.isRecordingDone])

  useEffect(() => {
    if (recordPluginInstance && startRecording) {
      dispatch(setRecordingStarted(true));
      setStartRecording(false);
      const deviceId = RecordPlugin.getAvailableAudioDevices().then((devices) => {
        return devices[0].deviceId;
      });
      setIsDisabled(true);
      setTimeout(() => {
        setIsDisabled(false)
      }, 1500)
      recordPluginInstance.startRecording({ deviceId }).then(() => {
        const id = uuidv4();
        dispatch(setReportId(id));
        createRecordingLog({ type:'start', duration: 0, reportId:id, createdAt: new Date().toISOString() });
        setActive(true);
        setPlayBackState('pause');
        setPlayback(false);

      });
    }
  }, [recordPluginInstance, startRecording]);

  // Helper function to safely create object URLs and track them
  const createSafeObjectURL = (blob) => {
    const url = URL.createObjectURL(blob);
    createdObjectURLs.push(url);
    return url;
  };

  // Helper function to safely revoke a specific URL
  const revokeSafeObjectURL = (url) => {
    const index = createdObjectURLs.indexOf(url);
    if (index !== -1) {
      URL.revokeObjectURL(url);
      createdObjectURLs.splice(index, 1);
    }
  };

  function readAsArrayBuffer(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(blob);
      reader.onloadend = () => { resolve(reader.result); };
      reader.onerror = (ev) => { reject(ev.error); };
    });
  }

  function injectMetadata(blob, duration) {
    if (blob.type?.startsWith("audio/webm")) {
      const decoder = new Decoder();
      const reader = new Reader();
      reader.logging = false;
      reader.drop_default_duration = false;
      readAsArrayBuffer(blob).then((buffer) => {
        const elms = decoder.decode(buffer);
        elms.forEach((elm) => { reader.read(elm); });
        reader.stop();
        console.log("reader.duration: ", reader.duration)
        console.log("duration: ", duration)
        let refinedMetadataBuf = tools.makeMetadataSeekable(
          reader.metadatas, duration, reader.cues
        );
        let body = buffer.slice(reader.metadataSize);
        let result = new Blob([refinedMetadataBuf, body],
          { type: blob.type });
        blob = result;
        console.log('finished recording:', blob);
        setFinalRecording(blob);
      });
    }
  }

  const onRecordEnd = async (blob) => {
    if (!blob || blob.size === 0) {
      console.log("Received an empty blob on end.");
      return;
    }
    if (!(window.isRecordingDone)) return;

    audioUrl.current = createSafeObjectURL(blob);
    await timeout(100)

    console.log("duration on end: ", window.duration)
    if (blob.type?.startsWith("audio/webm")) {
      injectMetadata(blob, Math.floor(window.duration * 1000));
    } else {
      setFinalRecording(blob);
    }
  }

  function timeout(delay) {
    return new Promise(res => setTimeout(res, delay));
  }

  const onDataAvailable = useCallback(async (blob) => {
    if (!restartStatusRef.current) {
      audioChunksRef.current.push(blob);
      if (audioChunksRef.current.length <= 2) {
        firstChunks.current.push(blob)// store first 2 chunk, needed for decoding
      }
      while (audioChunksRef.current.length > playbackDuration + pauseCount) {
        audioChunksRef.current.shift()
      }
    }
    restartStatusRef.current = false
  }, []);
  
  const resetState = () => {
    restartStatusRef.current = true
    setActive(false);
    setPlayback(false);
    handlePauseRecording(false)
    setProcessingUrl(false);
    audioChunksRef.current = []
    firstChunks.current = []
    pauseCount = 0
    setIsDisabled(true)
    setTimeout(() => {
      setIsDisabled(false)
    }, 1500)
  }

  const onStartStop = async () => {
    if (recordPluginInstance) {
      if (active) {
        // Stop recording and reset state 
        recordPluginInstance.stopRecording();
        createRecordingLog({ type:'discard', duration, reportId, createdAt: new Date().toISOString() });
        resetState()

        const deviceId = await RecordPlugin.getAvailableAudioDevices().then((devices) => {

          return devices[0].deviceId;
        });

        await timeout(100)
        recordPluginInstance.startRecording({ deviceId }).then(() => {
          createRecordingLog({ type:'restart', duration: 0, reportId, createdAt: new Date().toISOString() });
          setActive(true);
          setPlayBackState('pause');
          setTimeout(() => {
            setIsDisabled(false)
          }, 1500)
        });
      } else {
        // Start new recording
        const deviceId = await RecordPlugin.getAvailableAudioDevices().then((devices) => {
          return devices[0].deviceId;
        });

        setIsDisabled(true);
        recordPluginInstance.startRecording({ deviceId }).then(() => {
          setActive(true);
          setPlayBackState('pause');
          setPlayback(false);

          setTimeout(() => {
            setIsDisabled(false)
          }, 1500)
        });
      }
    }
  };

  useEffect(() => {
    setDurationProgress(duration);
  }, [duration]);
  
  // stop and process audio on 3 hours duration
  useEffect(() => {
    if ((durationProgress >= maxDuration )) {
      processAudio();
    }
  }, [durationProgress]);

  const processAudio = () => {
    if (recordPluginInstance) {
      window.isRecordingDone = true;
      window.duration = duration
      recordPluginInstance.stopRecording();
      createRecordingLog({ type:'end', duration, reportId, createdAt: new Date().toISOString() });
    }
    setRecordingTime(Math.floor(duration));
    setHidingtabs(true)
  };

  const onPauseResume = () => {
    // console.log("D1: ", duration)
    if (recordPluginInstance) {
      if (recordPluginInstance.isPaused()) {
        createRecordingLog({ type:'resume', duration, reportId, createdAt: new Date().toISOString() });
        recordPluginInstance.resumeRecording();
        setPlayBackState('pause');
        setPlayback(false);

        setIsDisabled(true)
        setTimeout(() => {
          setIsDisabled(false)
        }, 1500)
        handlePauseRecording(false); // Audio recording
        return;
      }
      handlePause();
    }
  };

  const handlePause = async () => {
    pauseCount++;
    console.log('pause count', pauseCount)
    setProcessingUrl(true);
    createRecordingLog({ type:'pause', duration, reportId, createdAt: new Date().toISOString() });
    recordPluginInstance.pauseRecording();
    handlePauseRecording(true); // Audio recording
    await timeout(1000)

    let combinedBlob;
    try {
      const chunksCount = audioChunksRef.current.length;
      console.log('chunksCount:', chunksCount)

      if (chunksCount >= (playbackDuration + pauseCount)) {
        const blob = new Blob(firstChunks.current, { type: firstChunks.current[0].type });
        audioChunksRef.current.unshift(blob)
      } 
      combinedBlob = new Blob(audioChunksRef.current, { type: audioChunksRef.current[0].type });
      console.log('combinedBlob:', combinedBlob)

      const arrayBuffer = await combinedBlob.arrayBuffer();
      await audioContext.decodeAudioData(arrayBuffer);

      if (audioUrl.current) {
        revokeSafeObjectURL(audioUrl.current);
      }
      const newAudioUrl = createSafeObjectURL(combinedBlob);
      audioUrl.current = newAudioUrl;
      setPlayback(true);
      setProcessingUrl(false);  
    } catch (err) {
      console.log("Error while processing:", err);
    }
  };

  const handlePlaybackState = (state) => {
    setPlayBackState(state);
  };

  const handlePlaybackPlayPause = () => {
    if (playBackState === 'pause') {
      setPlayBackState('resume');
      return;
    }
    setPlayBackState('pause');
  };

  useEffect(() => {
    if (recordPluginInstance) {
      recordPluginInstance.on('record-end', onRecordEnd);
      recordPluginInstance.on('record-data-available', onDataAvailable);
      recordPluginInstance.on('record-progress', (time) => {
        dispatch(setRecordingDuration(time / 1000));
      });
    }

  }, [recordPluginInstance]);

  return (
    <div className="recorder-wrapper">
      <div className={`w-full wave-container ${playback ? 'hidden' : 'block'}`}>
        <div className="flex items-center">
          <div className="flex-1 border-r-2 border-red-500" ref={containerRef}></div>
          {recordPluginInstance && active && (
            <div className="ml-2 flex justify-end">
              <div className="flex flex-col justify-center">
                <span className="dot"></span>
              </div>
              <span className="pl-3 mr-2 min-[350px]:mr-5 min-[350px]:pl-3 min-[350px]:w-[90px]">{formatTime(duration)}</span>
            </div>
          )}
        </div>
      </div>

      <div className={`w-full ${isPaused ? 'block' : 'hidden'}`}>
        {isPaused && (
          <Playback playbackAudioUrl={audioUrl.current} playBackState={playBackState} onPlaybackStateChange={handlePlaybackState} muted={audioChunksRef.current.length > (playbackDuration + pauseCount)}/>
        )}
      </div>
      <div className={`flex flex-row w-full mt-6 ${isPaused ? 'gap-2 grid md:grid-cols-2 grid-cols-1' : 'gap-2 flex-col sm:flex-row justify-between'}`}>
        <div className={`flex justify-start gap-2 ${isPaused ? 'grid grid-cols-2' : ''}`}>
          <AlertDialog>
            <AlertDialogTrigger className='w-full'>
              <Button variant="outline" className={`flex justify-center items-center w-full `}>
                <div className={`flex justify-center items-center ${isPaused ? 'gap-1': 'gap-[6px]'} `}>
                  <RotateCcw size={15} strokeWidth={3} className='text-red-500' />
                  <span>{t("actions.restart")}</span>
                </div>
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent className='w-fit'>
              <AlertDialogHeader>
                <AlertDialogTitle>{t("reports.generate.record.sure")}</AlertDialogTitle>
                <AlertDialogDescription>
                  {t("reports.generate.record.lostWarning")}
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter className='flex sm:justify-start'>
                <AlertDialogCancel className='w-full'>{t("common.no")}</AlertDialogCancel>
                <AlertDialogAction className='w-full' onClick={onStartStop}>{t("common.yes")}</AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
          {isPaused && (
            <Button
              onClick={handlePlaybackPlayPause}
              variant="outline"
              disabled={recordPluginInstance && !(recordPluginInstance.isPaused() || !active)}
              className={`flex justify-center items-center w-full ${(recordPluginInstance && !(recordPluginInstance.isPaused() || !active))} ? 'hidden w-full' : 'block w-full'`}
            >
              {playBackState === 'pause' ?
                <div className="flex gap-[4px] justify-center items-center">
                  <PlayIcon size={16} className='text-gray-500' />
                  <span>{t("actions.play")}</span>
                </div>
                :
                <div className='flex gap-[4px] justify-center items-center'>
                  <img src={PauseIcon} alt="pause icon" className='w-4 h-4 object-contain' />
                  <span>{t("actions.pause")}</span>
                </div>
              }
            </Button>
          )}
        </div>
        <div className={`flex gap-2 w-full items-center ${!isPaused && 'flex-col justify-end sm:flex-row'}`}>
          <Button key="pause-recording-button" onClick={onPauseResume} disabled={isDisabled || processingUrl} variant="outline" className={`w-full ${isPaused ? 'md:w-fit' : 'sm:w-fit'} items-center justify-center`}>
            {
              isDisabled || processingUrl ?
                <Loader spninnerClassName="m-auto" variant="small" /> :
                (recordPluginInstance.isPaused() ? 
                  <div className='flex gap-[6px] items-center justify-center'>
                    <img src={MicIcon} alt='Continue Icon' className='w-4 h-4 object-contain' />
                    <span>{t("actions.resume")}</span>
                  </div> : 
                  <div className='flex gap-[6px] items-center justify-center'>
                    <img src={PauseIcon} alt="pause icon" className='w-4 h-4 object-contain' />
                    <span>{t("actions.pause")}</span>
                  </div>
                )
            }
          </Button>

          <Button key="finish-recording-button" onClick={processAudio} recordButton
            className={`flex items-center justify-center w-full ${isPaused ? 'md:w-fit' : 'sm:w-fit'}`}
            variant="outline"
          >
            <div className='flex justify-center items-center gap-[6px]'>
              <img src={doneIcon} alt='' className='w-4 h-4 object-contain mb-[1px]' />
              <span>{t("actions.done")}</span>
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Recorder;
