import React, { useEffect, useState } from 'react';
import { useSubscription } from '../utils/SubscriptionContext';
import { useSelector } from 'react-redux';
import { getPayingCustomer } from '../redux/UserSlice';
import Button from './ui/Button';
import { useTranslation } from 'react-i18next';

const SubscriptionBar = ({noBorder}) => {
  const { daysLeft } = useSubscription();
  const payingCustomer = useSelector(getPayingCustomer);
  const { t } = useTranslation();
  const [trialMessage, setTrialMessage] = useState('');
  const [expiryMessage, setExpiryMessage] = useState('');
  const [buttonText, setButtonText] = useState('');

  useEffect(() => {
    // Set messages using i18next translation
    setTrialMessage(t('subscription.trialEndingSoon', { days: daysLeft }));
    setExpiryMessage(t('subscription.trialEndedMessage'));
    setButtonText(t('subscription.startPaidService'));
  }, [daysLeft, t]);

  if (daysLeft === null || payingCustomer === null || payingCustomer) {
    return null;
  }

  return (
    <div
      className={`w-full p-5 md:px-4 md:py-[10px] sticky top-0 left-0 z-50 flex text-center sm:flex-row sm:text-left flex-col gap-2 justify-between items-center ${!noBorder && 'border-b'} ${daysLeft > 0 ? 'bg-orange-100 text-orange-600' : 'bg-red-100 text-red-600'
        }`}

    >
      <p>
        {daysLeft > 0
          ? trialMessage
          : expiryMessage
        }
      </p>
      <div className='w-full sm:w-auto sm:min-w-[12rem] flex justify-center sm:justify-end '>
        <a
        href="mailto:hey@mpassist.ai"
          className={`  text-white text-[14px] rounded-lg w-[83vw] sm:w-auto `}
      >
          <Button variant={daysLeft > 0 ? 'trial' : 'expire'} size='subscription'>
            <p>{buttonText}</p>
        </Button>
      </a>
      </div>
    </div>
  );
};

export default SubscriptionBar;
