import React from 'react'
import { ToastContainer } from 'react-toastify'
import SubscriptionBar from './components/SubscriptionBar';
import { getPayingCustomer, selectNotification } from './redux/UserSlice';
import { useSelector } from 'react-redux';
import Header from './components/Header';
import { useLocation } from 'react-router-dom';
import { useSubscription } from './utils/SubscriptionContext';
import TrialEndModal from './components/TrialEndModal';
import Notification from './components/Notification';

function Layout({ children }) {
  const location = useLocation();
  const payingCustomer = useSelector(getPayingCustomer);
  const { daysLeft } = useSubscription();
  const userId = localStorage.getItem('user_id') || "";
  const invalidPaths = ['/reset-password', '/sign-up', '/verify-password'];
  const showHeader = userId ? !invalidPaths.includes(location.pathname) : false;
  const trialEnded = !(daysLeft === null || payingCustomer === null || payingCustomer) && (daysLeft <= 0)
  const notification = useSelector(selectNotification);

  return (
    <div>
      {trialEnded && <TrialEndModal />}
      <SubscriptionBar noBorder={notification}/>
      {!trialEnded && notification && <Notification text={notification} system={true}/>}
      <div className={` ${trialEnded ? 'blur pointer-events-none' : ''}`}>
        <div id='reportInfoBody' className={`App w-full flex flex-col items-center justify-center font-SuisseIntl`}>
          <ToastContainer />
          {showHeader && <Header payingCustomer={payingCustomer} />}
          <>
            {children}
          </>
        </div>
      </div>
    </div>
  )
}

export default Layout
