import React from 'react';
import AlertIcon from "../images/alert-circle.png";
import Button from './ui/Button';
import { useTranslation } from 'react-i18next';

function RecordingPopUp({onUploadNewRecording, setUploadNew, HandleReset }) {
  const { t } = useTranslation();
  const handleNewRecord = () => {
    HandleReset();
    onUploadNewRecording();
  };

  const handleCancel = () => {
    setUploadNew(false);
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative p-[22px] sm:p-[48px] max-w-[368px] w-[90vw] sm:w-full bg-white rounded-lg shadow flex flex-col items-center text-center">
        <div style={{ borderRadius: '20px' }} className='flex justify-center items-center w-[44px] h-[44px] bg-[#FFF3EA]'>
          <img src={AlertIcon} width={'24px'} height={'24px'} alt='Alert icon' />
        </div>
        <h1 className='mt-[16px] font-medium text-[16px]'>{t('reports.newPopup.willBeLost')}</h1>
        <p className='text-[14px] mt-[4px] font-SuisseIntlLight font-normal'>{t('reports.newPopup.confirmNew')}</p>
        <Button className='mt-[28px]' onClick={handleNewRecord}>
          {t('reports.newPopup.startNew')}
        </Button>
        <Button variant='light' className='mt-[12px]' onClick={handleCancel}>
          {t('reports.newPopup.cancel')}
        </Button>
      </div>
    </div>
  );
}

export default RecordingPopUp;
