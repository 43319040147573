import { createSlice } from "@reduxjs/toolkit";
import { FetchMembers, FetchSubstitutes, FetchTransactions, FetchPlans, FetchModels } from "./ProfileApis";

const initialState = {
    activeTab: 'general',
    members: [],
    substitutes: [],
    transactions: [],
    plans: [],
    models: [],
    loading: {
        members: false,
        substitutes: false,
        transactions: false,
        plans: false,
        models: false,
    }
};

const profileSlice = createSlice({
    name: "profileSlice",
    initialState,
    reducers: {
        setActiveTab: (state, action) => {
            state.activeTab = action.payload
        },
        setNewTransaction: (state, action) => {
            state.transactions.unshift(action.payload)
        },
        setNewSubstitutes: (state, action) =>{
            state.substitutes.unshift(action.payload)
        },
        updateMember: (state, action) => {
            const updatedMember = action.payload;
            state.members = state.members.map(member => member.id === updatedMember.id ? updatedMember : member)
        },
        removeSubstitute: (state, action) => {
            state.substitutes = state.substitutes.filter(substitute => substitute.id !== action.payload)
        },
        updateExistingSubstitute: (state, action) => {
            const updatedSubstitute = action.payload;
            state.substitutes = state.substitutes.map(substitute => substitute.id === updatedSubstitute.id ? updatedSubstitute : substitute)
        },
        removeMember: (state, action) => {
            state.members = state.members.filter(member => member.id !== action.payload)
        },
        addMember: (state, action) => {
            state.members.push(action.payload)
        }
    },
    extraReducers: (builder) => {
        builder.addCase(FetchMembers.pending, (state) => {
            state.loading.members = true;
        });
        builder.addCase(FetchMembers.fulfilled, (state, action) => {
            state.loading.members = false;
            state.members = action.payload?.members;
        });
        builder.addCase(FetchMembers.rejected, (state) => {
            state.loading.members = false;
        });
        builder.addCase(FetchSubstitutes.pending, (state) => {
            state.loading.substitutes = true;
        });
        builder.addCase(FetchSubstitutes.fulfilled, (state, action) => {
            state.loading.substitutes = false;
            state.substitutes = action.payload?.substitute;
        });
        builder.addCase(FetchSubstitutes.rejected, (state) => {
            state.loading.substitutes = false;
        });
        builder.addCase(FetchTransactions.pending, (state) => {
            state.loading.transactions = true;
        });
        builder.addCase(FetchTransactions.fulfilled, (state, action) => {
            state.loading.transactions = false;
            state.transactions = action.payload?.transactions;
        });
        builder.addCase(FetchTransactions.rejected, (state) => {
            state.loading.transactions = false;
        });
        builder.addCase(FetchPlans.pending, (state) => {
            state.loading.plans = true;
        });
        builder.addCase(FetchPlans.fulfilled, (state, action) => {
            state.loading.plans = false;
            state.plans = action.payload?.plans;
        });
        builder.addCase(FetchPlans.rejected, (state) => {
            state.loading.plans = false;
        });
        builder.addCase(FetchModels.pending, (state) => {
            state.loading.models = true;
        });
        builder.addCase(FetchModels.fulfilled, (state, action) => {
            state.loading.models = false;
            state.models = action.payload?.models;
        });
        builder.addCase(FetchModels.rejected, (state) => {
            state.loading.models = false;
        });
    },
});

export const { setLoading, setActiveTab, setNewSubstitutes, setNewTransaction, updateMember, updateExistingSubstitute, removeSubstitute, removeMember, addMember } = profileSlice.actions;

export const selectActiveTab = (state) => state?.profileSlice?.activeTab;
export const selectMembers = (state) => state?.profileSlice?.members;
export const selectSubstitutes = (state) => state?.profileSlice?.substitutes;
export const selectTransactions = (state) => state?.profileSlice?.transactions;
export const selectPlans = (state) => state?.profileSlice?.plans;
export const selectModels = (state) => state?.profileSlice?.models;
export const selectProfileLoading = (state) => state?.profileSlice?.loading;

export default profileSlice.reducer;
