import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { DialogWrapper } from '@/components/ui/CardWrapper';
import billingIcon from '../images/SVG/billingIcon.svg';
import { createSubstitute } from '@/utils/HelperFunctions';
import { setNewSubstitutes } from '@/redux/ProfileSlice';
import { Button } from './shadcn-ui/button';
import { Label } from "@/components/shadcn-ui/label";
import { Input } from "@/components/shadcn-ui/input";
import { Textarea } from './shadcn-ui/textarea';
import Loader from './Loader';
import { useTranslation } from 'react-i18next';

const AddSubstitute = ({ type, setIsOpen, title }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    find: '',
    replace: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { find, replace } = formData;
    try {
      setLoading(true);
      const newSubstitute = await createSubstitute({ find, replace, type });
      if (newSubstitute) {
        dispatch(setNewSubstitutes(newSubstitute));
      }
    } catch (error) {
      console.error('Failed to create substitute:', error);
    } finally {
      setIsOpen(false);
      setLoading(false);
    }
  };

  const description = type === 'vocabulary' ? t("profile.vocabulary.addDescription") : t("profile.textModules.addDescription");
  
  return (
    <DialogWrapper
      title={title}
      description={description}
      icon={billingIcon}
      setIsOpen={setIsOpen}
    >
      <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
        <div className="flex flex-col space-y-1.5">
          <Label htmlFor="find" className="font-semibold">{t("profile.find")}</Label>
          <Input
            required
            type="text"
            id="find"
            name="find"
            value={formData.find}
            onChange={handleChange}
            autoComplete="off"
            className="text-gray-900 font-normal "
          />
        </div>
        <div className="flex flex-col space-y-1.5">
          <Label htmlFor="replace" className="font-semibold">{t("profile.replace")}</Label>
          {type === 'vocabulary' ?
            <Input
              required
              type="text"
              id="replace"
              name="replace"
              value={formData.replace}
              onChange={handleChange}
              autoComplete="off"
              className="text-gray-900 font-normal "
            /> 
            :
            <Textarea
              required
              id="replace"
              name="replace"
              value={formData.replace}
              onChange={handleChange}
              autoComplete="off"
              className="text-gray-900 font-normal h-24"
            />
          }
        </div>
        <Button
          disabled={loading || !formData.find || !formData.replace}
          type="submit"
          className="w-full "  
        >
          {loading ? 
            <Loader />
          :
            <span>{t("actions.add")}</span>

          }
        </Button>
      </form>
    </DialogWrapper>
  );
};

export default AddSubstitute;
