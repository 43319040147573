import React, { useEffect, useState } from 'react'
import RecordAudio from '../components/RecordAudio'
import UploadFile from '../components/UploadFile';
import { Tabs } from '../components/ui/Tabs';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CardWrapper, MainCardWrapper } from '../components/ui/CardWrapper';
import { FetchReportTemplates } from '../redux/ReportsApis';
import { upperCase } from '@/utils/constants';
import { selectUserData } from '../redux/UserSlice';
import { useCurrentTool } from '@/utils/ToolHelpers';
import { FetchUser } from '@/redux/UserApis';
import { useTranslation } from 'react-i18next';

function AddReport() {
  // console.log('adding report', addingReport, 'generation', generation_name)
  const [hidingTabs, setHidingtabs] = useState(false);
  const userToken = localStorage.getItem('user_session')
  const userId = localStorage.getItem('user_id')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation(); // Update to use the 'reports' namespace

  const userData = useSelector(selectUserData);
  const { generation_name, toolId, toolInputs } = useCurrentTool();

  let isDisabled = {loading: true, noWords: true};
  isDisabled.loading = userData?.length === 0
  isDisabled.noWords = userData?.tokens === 0;

  const inputs = toolInputs.map((input, index)=>{
    const name = input.type.split('_').reverse().join(' ');
    return {
      name: upperCase(name),
      active: index === 0,
      value: input.type
    }
  })

  const [tabs, setTabs] = useState(inputs)
  const [selectedTab, setSelectedTab] = useState(inputs[0].value)

  useEffect(() => {
    dispatch(FetchReportTemplates({ toolId }))
  }, [dispatch, toolId])

  const handleTabChange = async (tabType) => {
    let updatedTabs = []

    tabs?.forEach((tab) => {
      if (tab.name === tabType) {
        setSelectedTab(tab.value)
        updatedTabs.push({
          name: tab.name,
          active: true,
          value: tab.value
        })
      }
      else {
        updatedTabs.push({
          name: tab.name,
          active: false,
          value: tab.value
        })
      }
    })
    setTabs(updatedTabs)

  };

  useEffect(() => {
    if (!userId) {
      navigate('/')
    }
  }, [userId, navigate])


  useEffect(() => {
    const userId = localStorage.getItem('user_id')
    if (!userId || userId === 'undefined') {
      return
    }
    dispatch(FetchUser({ userId, toolId }))

  }, [userToken, toolId, dispatch])

  const getToolInputs = () => {
    return (
      toolInputs?.map((input)=>{
        if(input.type === 'audio_record'){
          return (
            <div className={` ${selectedTab === input.type ? '': 'hidden'}`} key={input.type}>
              <CardWrapper className='border-none p-0'>
                <RecordAudio toolId={toolId} setHidingtabs={setHidingtabs} isDisabled={isDisabled} />
              </CardWrapper>
            </div>
          )
        }
        if(input.type === 'audio_upload'){
          return (
            <div className={` ${selectedTab === input.type ? '': 'hidden'}`} key={input.type}>
              <UploadFile toolId={toolId}  setHidingtabs={setHidingtabs} isDisabled={isDisabled}/>
            </div>
          )
        }
        return null;
      })
    )
  }


  return (
        <MainCardWrapper addingReport>
          <div className='text-center '>
            <div className="flex justify-between flex-wrap items-center">
        <div className={`${hidingTabs ? 'hidden' : 'hidden md:flex'}`}>
          <div className="text-left">
            <h1 className="font-medium text-[20px]">{t('reports.add.title', { type: generation_name })}</h1>
            <p className="text-[#505050] font-SuisseIntlLight font-normal mt-[4px] text-[14px]">{t('reports.add.subtitle')}</p>
          </div>
        </div>
     
      </div>
      <div className={` flex-col ${hidingTabs? 'hidden' :'flex md:hidden'}`}>
          <h1 className="font-semibold font-SuisseIntlLight text-left text-[16px] mt-[10px]">{t('reports.add.mobileTitle', { type: generation_name })}</h1>
          <p className="text-[#505050] font-SuisseIntlLight text-left font-normal mt-[4px] text-[14px]">{t('reports.add.mobileSubtitle')}</p>
        </div>
              
            <div className={`mt-[28px] mb-[24px] ${hidingTabs? 'hidden' :'block'}`}>
              <Tabs tabs={tabs} onChange={handleTabChange} />
            </div>
            {getToolInputs()}
          </div>
        </MainCardWrapper>
  )
}

export default AddReport