import React, { useState, useEffect } from 'react';
import { useSubscription } from '../utils/SubscriptionContext';
import frame from "../images/SVG/frame.svg";
import { useTranslation } from 'react-i18next';

const TrialEndModal = () => {
  const { t } = useTranslation();
  const { daysLeft } = useSubscription();
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')

  useEffect(() => {
    // Set title and description using i18next
    setTitle(t('trial.endPeriod'));
    setDescription(t('trial.endMessage'));
  }, [t]);

  useEffect(() => {
    if (daysLeft !== null && daysLeft <= 0) {
      setShowModal(true);
    }
  }, [daysLeft]);

  if (!showModal) return null;

  return (
    <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center min-h-screen z-10 ">
      <div className="absolute inset-0 bg-black bg-opacity-0 z-40"></div>
      <div className="bg-white mx-4 p-8 rounded-lg w-96 relative z-50 border border-[#E2E4E9] font-SuisseIntl">
        <div className="flex justify-center items-center bg-red-100 rounded-full w-16 h-16 mx-auto mb-4">
          <img src={frame} alt=""/>
        </div>
        <h2 className="flex flex-row text-[#000000]  justify-center text-xl font-medium mb-[4px]">{title}</h2>
        <p className="font-inter text-[#505050] text-center text-[15px] font-normal leading-[24px]">{description}</p>

      </div>
    </div>
  );
};

export default TrialEndModal;
