import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
} from "@/components/shadcn-ui/card";
import { Label } from "@/components/shadcn-ui/label";
import { Input } from "@/components/shadcn-ui/input";
import { Link } from "react-router-dom";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/shadcn-ui/select";
import { ProfileWrapper } from "../ui/CardWrapper";
import { useDispatch, useSelector } from "react-redux";
import { selectUserData, setUserData } from "@/redux/UserSlice";
import { selectModels } from "@/redux/ProfileSlice";
import { assignModel } from "@/utils/HelperFunctions";
import { toast } from "react-toastify";
import LanguageSwitcher from "../LanguageSwitcher";
import { useTranslation } from "react-i18next";

const General = () => {
  const { t } = useTranslation();
  const email = localStorage.getItem('email');
  const dispatch = useDispatch();
  const user = useSelector(selectUserData);
  const models = useSelector(selectModels)?.slice().sort((a, b) => a.name.localeCompare(b.name));
  
  const [loading, setLoading] = useState(false);
  const [selectedModelId, setSelectedModelId] = useState(user?.model_id || "");

  useEffect(() => {
    setSelectedModelId(user?.model_id || "");
  }, [user]);

  const updateModel = async (id) => {
    setLoading(true);
    const prevModelId = selectedModelId;
    setSelectedModelId(id);

    const response = await assignModel({ modelId: id });

    if (response) {
      toast.success(t("profile.general.modelUpdated"));
      dispatch(setUserData({ ...user, model_id: id }));
    } else {
      setSelectedModelId(prevModelId);
    }

    setLoading(false);
  };

  return (
    <ProfileWrapper
      title={t("profile.tabs.general")}
      description={t("profile.general.description")}
      hasButton={false}
    >
      <CardContent className="px-0 relative">
        <Card className={`rounded-2xl ${user?.is_admin ? 'h-[255x]' : 'h-[180px]'}`}>
          <CardContent className="p-5 h-full">
            <form action="" className="flex flex-col flex-wrap gap-4">
              <div className="flex flex-col gap-[3px]">
                <div className="flex justify-between items-center">
                  <Label className="font-SuisseIntlLight font-semibold">
                    {t("common.email")}
                  </Label>
                  <Link to="mailto:hey@mpassist.ai" className="font-SuisseIntlLight font-semibold text-[14px] text-[#71717A] underline">
                    {t("profile.general.changeEmail")}
                  </Link>
                </div>
                <Input
                  className="font-semibold placeholder:font-SuisseIntlLight placeholder:font-semibold"
                  placeholder="george@mail.com"
                  value={email}
                  disabled
                  type="email"
                />
              </div>
              <div className="flex flex-col space-y-1.5">
                <Label className="font-semibold font-SuisseIntlLight">
                  {t("profile.general.language")}
                </Label>
                <LanguageSwitcher />
              </div>
              {user?.is_admin && (
                <div className="flex flex-col space-y-1.5">
                  <Label className="font-semibold font-SuisseIntlLight">
                    {t("profile.general.model")}
                  </Label>
                  <Select
                    id="model-select"
                    value={selectedModelId}
                    onValueChange={(value) => {
                      const selectedModel = models.find((model) => model.id === value);
                      if (selectedModel) {
                        updateModel(selectedModel.id);
                      }
                    }}
                    disabled={loading}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder={t("profile.general.selectModel")} />
                    </SelectTrigger>
                    <SelectContent>
                      {models.map((model) => (
                        <SelectItem key={model.id} value={model.id}>
                          {model.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              )}
            </form>
          </CardContent>
        </Card>
      </CardContent>
    </ProfileWrapper>
  );
};

export default General;
