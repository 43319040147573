import React from 'react'
import NoReportIcon from '../images/SVG/emptyReportIcon.svg'
import { GradientBorder } from './ui/GradientBorder'
import { useTranslation } from 'react-i18next'

function NoReportFound({generation_name}) {
  const { t } = useTranslation();
  
  return (
    <div className='rounded-2xl  flex flex-col justify-center items-center text-center'>
      <GradientBorder>
        <div className="shadow-sm bg-white rounded-full px-4 py-4">
          <img width={'24px'} src={NoReportIcon} alt='no report icon found'/>
        </div>
      </GradientBorder>
      <h1 className='md:text-[24px] text-xl font-SuisseIntlLight font-extrabold md:mt-[16px]'>{t('reports.noReport.dontHave', { type: generation_name?.toLowerCase() })}</h1>
      <p className='text-[16px] font-SuisseIntlLight font-semibold text-[#505050] mt-[4px]'>{t('reports.noReport.createFirst')}</p>

    </div>
  )
}

export default NoReportFound
