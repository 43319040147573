import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectToolFields, selectSelectedTool, setSelectedTool, selectToolInputs, selectToolOutputs, selectToolLoading } from '@/redux/ToolsSlice';
import { FetchToolFields, FetchToolInputs, FetchToolOutputs } from '@/redux/ToolsApis';
import { useTranslation } from 'react-i18next';

export const useCurrentTool = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedTool = useSelector(selectSelectedTool);
  const allToolFields = useSelector(selectToolFields);
  const allToolInputs = useSelector(selectToolInputs);
  const allToolOutputs = useSelector(selectToolOutputs);
  const loading = useSelector(selectToolLoading);
  const userId = localStorage.getItem('user_id') || '';
  
  const currentTool = useCallback(() => {
    return allToolFields?.find(tool => tool.name === selectedTool) || [];
  }, [allToolFields, selectedTool]);

  useEffect(() => {
    if (!userId) return;

    if (!allToolFields?.length && !loading.tools) {
      dispatch(FetchToolFields());
    }
    if (!allToolInputs?.length && !loading.inputs) {
      dispatch(FetchToolInputs());
    }
    if (!allToolOutputs?.length && !loading.outputs) {
      dispatch(FetchToolOutputs());
    }
    if (!selectedTool) {
      const path = window.location.pathname.split('/')[1];
      const tool = allToolFields?.find(tool => tool.name.split(' ')[0].toLowerCase() === path);
      
      if (tool) {
        dispatch(setSelectedTool(tool.name));
      }
    }
  }, [dispatch, allToolFields, selectedTool, allToolInputs, allToolOutputs, loading, userId]);

  const currentToolFields = currentTool().fields || [];

  const name = currentTool().generation_name || '';
  const generation_name = name ? t(`common.${name.toLowerCase()}`) : name;
  const gen_name_plural = name ? t(`common.${name.toLowerCase()}s`) : `${name}s`;
  const toolId = currentTool().id || '';

  const toolInputs = allToolInputs?.find(tool => tool.id === toolId)?.inputs || [];
  const toolOutputs = allToolOutputs?.find(tool => tool.id === toolId)?.outputs || [];

  const getToolDescription = (tool_id) => {
    const tool = allToolFields?.find(tool => tool.id === tool_id);
    return tool ? `${selectedTool ? '' : tool.name+"'s "}${generation_name}` : '';
  };

  return { currentToolFields, toolId, generation_name, gen_name_plural, toolInputs, toolOutputs, toolName:selectedTool, getToolDescription };
};