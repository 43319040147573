import { useTranslation } from "react-i18next";

export function Tabs({ tabs, onChange, className }) {

  return (
    <div className="rounded-[10px] p-1.5  bg-[#F6F8FA] flex gap-1 text-sm w-full overflow-x-scroll sm:overflow-hidden">
      {tabs?.map((tab, index) => (
        <Tab key={index} onChange={onChange} active={tab.active} name={tab.name} />
      ))}
    </div>
  );
}

function Tab({ active, name, onChange }) {
  const { t } = useTranslation();
  return (
    <button
      onClick={() => { onChange(name) }}
      className={`py-1 px-4 font-semibold text-[12px] md:text-base font-SuisseIntlLight md:min-w-[150px] min-w-fit grow rounded-md ${
        active ? "bg-white tab-shadow" : "hover:bg-[#EEEEEE] text-[#525866]"
      }`}
    >
      {getTranslatedName(t, name)}
    </button>
  );
}

const getTranslatedName = (t, name) => {
  if (name === 'Record Audio') {
    return t("actions.recordAudio")
  } else if (name === 'Upload Audio') {
    return t("actions.uploadAudio") 
  } else {
    return name
  }
}