import { clsx } from "clsx";
import { twMerge } from "tailwind-merge"

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const capitalizeFirstLetter = (str) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const shouldAddReportBasedOnFilters = (updatedReport, toolName, currentToolFields) => {
  if (!toolName) return true;
  
  const filtersStr = localStorage.getItem(`${toolName} filters`);
  if (!filtersStr) return true;
  
  try {
    const filters = JSON.parse(filtersStr);
    
    if (filters.startDate && filters.startDate.trim() !== '') {
      const reportDate = new Date(updatedReport.created_at);
      const startDate = new Date(filters.startDate);
      if (reportDate < startDate) return false;
    }
    
    if (filters.endDate && filters.endDate.trim() !== '') {
      const reportDate = new Date(updatedReport.created_at);
      const endDate = new Date(filters.endDate);
      // Set end date to end of day
      endDate.setHours(23, 59, 59, 999);
      if (reportDate > endDate) return false;
    }
    
    if (filters.comment && filters.comment.trim() !== '') {
      const reportComment = updatedReport.comment || '';
      if (!reportComment.toLowerCase().includes(filters.comment.toLowerCase())) return false;
    }
    
    return currentToolFields.every(field => {
      const fieldName = field.name;
      // Only check fields that have filter values
      if (filters[fieldName] && filters[fieldName].trim() !== '') {
        return updatedReport.fields[fieldName] === filters[fieldName];
      }
      return true;
    });
  } catch (e) {
    console.error("Error parsing filters:", e);
    return true;
  }
};