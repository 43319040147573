import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { 
  DropdownMenu, 
  DropdownMenuContent, 
  DropdownMenuItem, 
  DropdownMenuTrigger 
} from "@/components/shadcn-ui/dropdown-menu";
import { useSelector } from 'react-redux';
import { selectReportsTotalCount } from '@/redux/ReportsSlice';

const StatusDropdown = ({ tabs, selected, handler, reports, disable = false, showReportCount = false, isDrawer }) => {
  const selectedText = tabs.find((tab) => tab.value === selected)?.name;
  const reportsCount = useSelector(selectReportsTotalCount)

  return (
    <DropdownMenu>
      <DropdownMenuTrigger 
        disabled={disable} 
        className={`border-[1px] h-10 border-[#E2E4E9] inline-flex items-center justify-center gap-x-1.5 rounded-[10px] bg-white px-4 py-2 text-sm font-normal text-gray-900 hover:border-[#CED0D5]`}
      >
        <span 
          className={`flex ${isDrawer ? "text-black font-medium text-[14px]" : "text-[#525866]"} h-full items-center justify-center whitespace-nowrap overflow-hidden text-ellipsis`}
        >
          {selectedText}
        </span>
        <ChevronDownIcon 
          className={`h-5 w-5 ${isDrawer ? "text-black" : "text-gray-400"}`} 
          aria-hidden="true" 
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent align='start' className='p-2'>
        {tabs.map((tab) => (
          <DropdownMenuItem 
            key={tab.value}
            onSelect={() => handler(reports ? tab.name : tab?.value, selected)}
            className="cursor-pointer py-2 text-[14px] text-gray-700 focus:text-gray-900"
          >
            <span className="inline-block">
              <span>{tab.name}</span>
              {showReportCount && ( 
                <> 
                  <span className="mx-1">&middot;</span>
                  <span>{reportsCount[tab.value]}</span>
                </>
              )}
            </span>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default StatusDropdown;
