import React, { useEffect } from 'react';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';
import './App.css';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import NotFoundPage from './pages/NotFound';
import MainPage from './pages/MainPage';
import Reports from './pages/Reports';
import Report from './pages/Report';
import useWebSocket from 'react-use-websocket';
import { useDispatch, useSelector } from 'react-redux';
import { FetchBackendReports } from './redux/ReportsApis';
import { UpdateReport, selectIntegration, setIntegration, setLatestPayload } from './redux/ReportsSlice';
import { pageSize } from './utils/constants';
import { SubscriptionProvider } from './utils/SubscriptionContext';
import { setPayingCustomer, getPayingCustomer, selectLanguage } from './redux/UserSlice';
import Layout from './Layout';
import { setNavigate } from './utils/authSession';
import Home from './pages/Home';
import ForgotPass from './pages/ForgotPass';
import SignUp from './pages/SignUp';
import VerifyPass from './pages/VerifyPass';
import Profile from './pages/Profile';
import { SidebarProvider } from './components/shadcn-ui/sidebar';
import { useCurrentTool } from './utils/ToolHelpers';
import { setSelectedTool, selectToolFields } from './redux/ToolsSlice';
import { FetchToolFields } from "@/redux/ToolsApis";
import Loader from './components/Loader';
import { store } from './redux/Store';
import { useTranslation } from 'react-i18next';

const WS_URL = process.env.REACT_APP_WS_URL

const App = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const language = useSelector(selectLanguage)
  const userId = localStorage.getItem('user_id') || "";
  const { toolId, generation_name, toolName, currentToolFields, getToolDescription } = useCurrentTool();

  const payingCustomer = useSelector(getPayingCustomer);
  const tools = useSelector(selectToolFields);
  const integration = useSelector(selectIntegration);

  const notIncludesAuthRoutes = !['/reset-password', '/sign-up', '/verify-password'].includes(location.pathname);
  const isLoadingTools = !tools?.length && location.pathname !== '/' && notIncludesAuthRoutes;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language, i18n]);

  useEffect(() => {
    dispatch(setSelectedTool(''));
    if (!tools?.length)
      Promise.all([
        dispatch(FetchToolFields()),
      ]);
  }, [dispatch, tools?.length]);

  useEffect(() => {
    if (integration.exists && integration.path !== location.pathname) {
      dispatch(setIntegration('reset'));
    }
  }, [dispatch, integration, location.pathname]);

  useEffect(() => {
    setNavigate(navigate, store);
  }, [navigate]);

  useEffect(() => {
    window.Intercom("update");
  }, [location]);

  useEffect(() => {
    if (!userId) {
      notIncludesAuthRoutes && navigate('/');
      return;
    }
    // Clear the previous paying customer state
    dispatch(setPayingCustomer(null));

    // Simulate fetching the paying customer status
    const fetchPayingCustomerStatus = async () => {
      dispatch(setPayingCustomer(payingCustomer));
    };

    fetchPayingCustomerStatus();
  }, 
  [dispatch, navigate, userId, payingCustomer, notIncludesAuthRoutes]);

  const { lastJsonMessage } = useWebSocket(
    `${WS_URL}?userId=${userId}`,
    {
      onOpen: () => console.log('WebSocket connection established.'),
      onClose: () => console.log('WebSocket connection closed.'),
      onMessage: (event) => console.log('Event: ', event),
      onError: (error) => {
        console.error('WebSocket error: ', error);
      },
      shouldReconnect: () => true,
      heartbeat: {
        message: 'ping',
        returnMessage: 'pong',
        timeout: 5 * 60000,
        interval: 5 * 1000,
      },
    }
  );

  useEffect(() => {
    if (!lastJsonMessage) return;
    
    const { tool_id, row_number, status, report_fetched, error } = lastJsonMessage.data;
    console.log("Notification: ", lastJsonMessage.data);
    
    dispatch(setLatestPayload(lastJsonMessage.data));
  
    if (toolId !== tool_id) return;
  
    dispatch(UpdateReport({report: lastJsonMessage.data, toolName, currentToolFields}));
    
    const statusMap = new Set(["generated", "urgent", "processing", "in-progress"]);
    if (statusMap.has(status) && userId) {
      dispatch(FetchBackendReports({ userId, status, page: 1, pageSize, toolId }));
    }
    
    if (status === "completed" && !report_fetched) {
      const errorMessage = error === "no_transcription_found" 
        ? t("form.errors.transcriptionFailed")
        : t("form.errors.generationFailed", { type: generation_name.toLowerCase() });
      
      toast.error(`${getToolDescription(tool_id)} #${row_number}: ${errorMessage}`, { toastId: `toast-${tool_id}` });
    }
  }, [dispatch, userId, lastJsonMessage, toolId, generation_name, toolName, currentToolFields, t, getToolDescription]);

  return (
    <SubscriptionProvider>
      <SidebarProvider>
      <Layout>
        {isLoadingTools ? 
          <div className='w-full h-screen flex justify-center items-center'>
            <Loader/>
          </div>  
      :
        <Routes>
        <Route path='/' element={userId ? <Home/> : <MainPage/>} />
          {isLoadingTools ? (
              <Loader/>
            ) : (
              tools?.map((tool) => {
                const word = tool.name.split(' ')[0].toLowerCase();
                return <Route key={tool.name} path={`/${word}`} element={<Reports />} />;
              })
            )}
          {tools?.map(tool => {
            const word = tool.name.split(' ')[0].toLowerCase();
            return <Route key={word} path={`/${word}/:id`} element={<Report />} />
          })}
          <Route path='*' element={<NotFoundPage />} />
          <Route path='/settings' element={<Profile/>}/>
          <Route path='/reset-password' element={userId ? <NotFoundPage/> :<ForgotPass/>}/>
          <Route path='/sign-up' element={userId ? <NotFoundPage /> : <SignUp/>}/>
          <Route path='/verify-password' element={userId ? <NotFoundPage/> : <VerifyPass/>}/>
        </Routes>
      }
      </Layout>
      </SidebarProvider>
    </SubscriptionProvider>
  );
};

export default App;
