import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";
import Notification from "../components/Notification";
import CopyToClipboard from "react-copy-to-clipboard";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { NoPaddingCardWrapper } from "../components/ui/CardWrapper";
import { UpdateReportStatus, FetchReport } from "../redux/ReportsApis";
import loading from "../images/generating.gif";
import { getSignedUrl, UpdateReportDetails } from "../utils/HelperFunctions";
import { fetchAuthSessionWithLogout } from "../utils/authSession";
import Loader from "../components/Loader";
import StatusDropdown from "../components/StatusDropdown";
import { seletecReportLoader, setReportUpdate } from "../redux/ReportsSlice";
import EditIcon from "../images/SVG/editIcon.svg";
import CopyFile from "../images/SVG/copyfileIcon.svg";
import fileDownload from "../images/SVG/fileDownload.svg";
import { useCurrentTool } from "@/utils/ToolHelpers";
import { formatTimestamp } from "@/utils/date";
import { Button } from "@/components/shadcn-ui/button";
import { Label } from "@radix-ui/react-label";
import { Input } from "@/components/shadcn-ui/input";
import { Textarea } from "@/components/shadcn-ui/textarea";
import { useTranslation } from "react-i18next";

function Report({ isDrawer = false }) {
  const { t } = useTranslation();
  const report = useSelector((state) => state?.reportsSlice?.selectedReport);
  // const fileUpdating = !(useSelector(state => state?.reportsSlice?.selectedReport));
  const fileUpdating = !report?.report_fetched;
  // const fileUpdating =!(useSelector(state => state?.reportsSlice?.updating));
  const [showNotification, setShowNotification] = useState(false);
  const [editClicked, setEditClicked] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;
  const userToken = localStorage.getItem("user_id");
  const [reportStatus, setReportStatus] = useState(null);
  const [downloadingReport, setDownloadingReport] = useState(false);
  const [downloadingFile, setDownloadingFile] = useState(false);
  const reportLoader = useSelector(seletecReportLoader);
  const [editLoading, setEditLoading] = useState(false);
  const [navigated, setNavigated] = useState(false);
  const { currentToolFields, generation_name, toolOutputs } = useCurrentTool();
  const [dynamicFields, setDynamicFields] = useState(() => 
    currentToolFields.reduce((acc, field) => {
      acc[field.name] = '';
      return acc;
    }, {})
  );
  const [staticFields, setStaticFields] = useState({
    comment: '',
    report: '',
  });
  const allowedStatus = [
    {
      name: t('reports.tabs.generated'),
      active: false,
      value: "generated",
    },
    {
      name: t('reports.tabs.inProgress'),
      active: false,
      value: "in-progress",
    },
    {
      name: t('reports.tabs.completed'),
      active: false,
      value: "completed",
    },
    {
      name: t('reports.tabs.urgent'),
      active: false,
      value: "urgent",
    },
  ];

  useEffect(() => {
    if (!userToken) {
      navigate("/");
    }
  }, [userToken, navigate]);

  const downloadAudioFile = async () => {
    try {
      setDownloadingFile(true);
      // TODO: Add meta_type to backend
      const signedUrl = await getSignedUrl({
        id: report?.id,
        name: report?.id,
        type: "download",
        meta_type: report?.meta_type,
      });
      window.open(signedUrl, '_self')
    } catch (error) {
      console.log("Downloading error ", error);
      toast.error("Error while downloading the file. Please try again");
    } finally {
      setDownloadingFile(false);
    }
  };

  useEffect(() => {
    if (JSON.stringify(report) === "{}") {
      if (id) {
        dispatch(FetchReport({ row_number: id, currentToolFields }));
      }
    } else {
      if (!report.report) {
        dispatch(FetchReport({ id: report.id, currentToolFields }));
      }
    }
  }, [navigate, report, id, currentToolFields, dispatch]);

  const handleCopyClicked = () => {
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 2000);
  };

  const components = {
    h1: ({ children, ...props }) => (
      <h1
        style={{
          fontSize: "1.5em",
          fontWeight: "500",
          color: "#71717a",
          margin: "0 0 10px 0",
        }}
        {...props}
      >
        {children}
      </h1>
    ),
    h2: ({ children, ...props }) => (
      <h2
        style={{
          fontSize: "1.2em",
          fontWeight: "500",
          color: "#71717a",
          margin: "0 0 10px 0",
        }}
        {...props}
      >
        {children}
      </h2>
    ),
    h3: ({ children, ...props }) => (
      <h3
        style={{
          fontSize: "1.17em",
          fontWeight: "500",
          color: "#71717a",
          margin: "0 0 10px 0",
        }}
        {...props}
      >
        {children}
      </h3>
    ),
    p: ({ children, ...props }) => (
      <p
        style={{ fontSize: "14px", fontWeight: "400", margin: "0 0 15px 0" }}
        {...props}
      >
        {children}
      </p>
    ),
    ul: ({ children, ...props }) => (
      <ul style={{ paddingLeft: "20px", marginBottom: "20px", listStyleType: "circle" }} {...props}>
        {children}
      </ul>
    ),
    li: ({ children, ...props }) => (
      <li
        style={{ fontSize: "14px", fontWeight: "400", marginBottom: "5px" }}
        {...props}
      >
        {children}
      </li>
    ),
    strong: ({ children, ...props }) => (
      <strong
        style={{ color: "#71717a", fontWeight: "500", fontSize: "14px" }}
        {...props}
      >
        {children}
      </strong>
    ),
  };

  const convertMdtoDocx = async (report) => {
    try {
      if (downloadingReport) return;

      setDownloadingReport(true);
      const id = report.id;
      const accessToken = await fetchAuthSessionWithLogout();
      if (!accessToken) {
        return;
      }
      const response = await fetch(
        `${process.env.REACT_APP_AWS_API_ENDPOINT}/md-to-docx-pandoc/${id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken.toString()}`,
          },
          body: JSON.stringify({}),
        }
      );
      console.log("result", response);

      if (!response.ok) {
        console.log("Error, could not fetch converted docx");
        return;
      }

      const docxDownloadLink = await response.json();
      const { downloadUrl } = docxDownloadLink;
      console.log("download link,", docxDownloadLink);
      console.log("url,", downloadUrl);

      const link = document.createElement("a");
      link.download = `${generation_name} - ${report.row_number}.docx`;
      link.href = downloadUrl;

      setDownloadingReport(false);
      link.click();
    } catch (err) {
      console.log("Failed to convert report to docx, Full Error - ", err);
      setDownloadingReport(false);
    }
  };

  const handleStatusUpdate = (status, oldStatus) => {
    setReportStatus(status);
    dispatch(setReportUpdate(true));
    dispatch(UpdateReportStatus({ id: report?.id, status, oldStatus }));
  };

  const downloadIcon = (state) => {
    return (
      state ?
        <img
          src={loading}
          alt="loading icon"
          className="w-5 h-5"
          tooltip="Downloading"
        />
        :
        <img
          src={fileDownload}
          alt="download icon"
          className="w-5 h-5"
          tooltip="Download as doc"
        />
    )
  }

  const getToolOutputs = () => {
    return toolOutputs?.map((output) => {
      if (output.type === "audio_download") {
        return (
          <div
            onClick={downloadAudioFile}
            className="border cursor-pointer text-sm h-10 p-2 px-4 gap-2 rounded-[10px] hover:border-[#CED0D5] border-[#E2E4E9] flex justify-center items-center"
          >
            {downloadIcon(downloadingFile)}
            <span className="text-black font-medium">{t("common.audio")}</span>
          </div>
        );
      }
      if (output.type === "docx_download") {
        return (
          <>
            <div
              onClick={() => convertMdtoDocx(report)}
              className="border cursor-pointer text-sm h-10 p-2 px-4 gap-2 rounded-[10px] hover:border-[#CED0D5] border-[#E2E4E9] flex justify-center items-center"
            >
              {downloadIcon(downloadingReport)}
              <span className="text-black font-medium">{t("common.document")}</span>
            </div>
          </>
        );
      }
      return null;
    });
  };

  const reportText = useMemo(() => `${report?.report}\n`?.split(" \\\n")?.join("\n"), [report]);
  const copyText = useMemo(() => report?.report?.split(" \\\n")?.join("\n")?.replace(/([\\#*])+\s*/g, ''), [report]);
  
  useEffect(() => {
    const updatedDynamicFields = currentToolFields.reduce((acc, field) => {
      acc[field.name] = report.fields?.[field.name] || '';
      return acc;
    }, {});

    setDynamicFields(updatedDynamicFields);
    setStaticFields({ comment: report?.comment || '', report: report?.report || ''});

  }, [dispatch, report, currentToolFields]);

  useEffect(() => {
    if (!reportLoader && navigated) {
      toast.success(`${generation_name} updated successfully!`)
      setEditClicked(false);
      setEditLoading(false);
    }
  }, [reportLoader, navigated, generation_name]);

  const handleInputChange = ({ key, value }) => {
    if (currentToolFields.some(field => field.name === key)) {
      setDynamicFields(prevState => ({
        ...prevState,
        [key]: value
      }));
    } else {
      setStaticFields(prevState => ({
        ...prevState,
        [key]: value
      }));
    }
  }

  const handleEditSave = async () => {
    setEditLoading(true)

    const tool_fields = currentToolFields.map((field) => ({
      id: field.id || '',
      value: dynamicFields[field.name] || '',
    }));

    const dataToSend = {
      ...staticFields,
      id: report.id,
      tool_fields
    };

    const detailUpdated = await UpdateReportDetails({ dataToSend })
    if (detailUpdated) {
      dispatch(FetchReport({ id: report.id, currentToolFields }))
    }
    setNavigated(true)
  }

  const handleEditCancel = () => {
    setEditClicked(false);
    setEditLoading(false);
    setNavigated(false);
    setDynamicFields(currentToolFields.reduce((acc, field) => {
      acc[field.name] = report.fields?.[field.name] || '';
      return acc;
    }, {}));
    setStaticFields({ comment: report?.comment || '', report: report?.report || ''});
  }

  return (
    <NoPaddingCardWrapper isDrawer={isDrawer} className={`flex flex-col gap-5 overflow-auto no-scrollbar ${isDrawer ? '' : 'px-6 mb-5'}`}>

      <div className={`flex md:gap-2 gap-2.5 justify-between py-6 ${editClicked ? '-mt-2' : ''} ${isDrawer ? "px-0 md:flex-row flex-col" : "flex-col"}`}>
        <div className={`${isDrawer ? "hidden" : "flex"} justify-start flex-col sm:flex-row md:gap-4`}>
          <div className="ml-0 mt-[10px] sm:mt-0 text-left">
            <p className="text-black text-[20px] md:block font-SuisseIntlLight font-semibold capitalize	mt-[4px] md:mt-0 break-words">
            {report.fields?.[currentToolFields?.[0].name]}
            </p>
            <p className="text-[#505050] inline-block text-[14px] font-SuisseIntlLight font-normal justify-center">
              {generation_name} {report.row_number}, {formatTimestamp(report.created_at, 'd/m/y')}
            </p>
          </div>
        </div>
        <div className={`flex-col w-full flex gap-6`}>
          {!editClicked &&
            <>
              <div className="w-full grid grid-rows-1 sm:grid-cols-2 gap-3">
                <div className="flex gap-3 items-center">
                  <CopyToClipboard
                    disabled={!report.report}
                    text={!!report.report ? copyText : "N/A"}
                    onCopy={handleCopyClicked}
                  >
                    <div onClick={handleCopyClicked} className="border rounded-[10px] cursor-pointer w-[60px] h-[40px] hover:border-[#CED0D5] border-[#E2E4E9] flex justify-center items-center">
                      <img src={CopyFile} alt="copy icon not found" className="max-w-none w-4 h-4" />
                    </div>
                  </CopyToClipboard>
                  <div className="block w-full">
                    {!!report.status && (
                      <StatusDropdown
                        isDrawer
                        tabs={allowedStatus}
                        mobileView={true}
                        disable={fileUpdating}
                        selected={reportStatus ? reportStatus?.toLowerCase() : report.status?.toLowerCase()}
                        reports={false}
                        handler={handleStatusUpdate}
                      />
                    )}
                  </div>
                </div>
                <div className="flex gap-3 sm:place-self-end">
                  {getToolOutputs()}
                  <div
                    className="border cursor-pointer p-2 px-3 rounded-[10px] h-10 w-[40px] hover:border-[#CED0D5] border-[#E2E4E9] flex justify-center items-center"
                    onClick={() => setEditClicked(true)}
                  >
                    <img src={EditIcon} alt="edit icon" className="max-w-none h-4 w-4" />
                  </div>
                </div>
              </div>
            </>
          }
          {!editClicked && <div className="-mx-[24px] border-t border-t-[#E5E7EC]"/>}
          <div className={`grid ${editClicked ? 'grid-cols-3 gap-3' : 'grid-cols-2 gap-3 sm:grid-cols-4'}`}>
            {currentToolFields?.map((field) => (
              editClicked ?
                <div key={field.name} className="flex flex-col gap-1.5">
                  <Label>{t(`fields.${field.name}`)}</Label>
                  <Input
                    name={field.name}
                    value={dynamicFields[field.name] || ''}
                    type='text'
                    onChange={(e) => handleInputChange({ key: field.name, value: e.target.value })}
                  />
                </div>
                :
                <div key={field.name}>
                  <p className="text-sm text-[#71717a] font-medium">
                    {t(`fields.${field.name}`)}
                  </p>
                  <p className="text-sm text-black font-medium">
                    {report.fields?.[field.name]}
                  </p>
                </div>
            ))}
            {!editClicked &&
              <div>
                <p className="text-[14px] text-[#71717a] font-medium">
                  {t("common.date")}
                </p>
                <p className="text-[14px] text-black font-medium">
                  {formatTimestamp(report.created_at, 'd.m.y')}
                </p>
              </div>
            }
          </div>
        </div>
      </div>
      {!editClicked && <div className={`${isDrawer && '-mx-6'}  -mt-6 border-t border-t-[#E5E7EC]`} />}
      {!report.report ? (
        <div className="p-[20px] sm:p-[24px]">
          <Loader className="w-full flex justify-center" />
        </div>
      ) : (
        <>
          {editClicked ?
            <div className="flex flex-col gap-1.5 -mt-6">
              <Label>{t("common.reportText")}</Label>
              <Textarea
                className='h-[45vh] no-scrollbar'
                value={staticFields.report || ''}
                onChange={(e) => handleInputChange({ key: 'report', value: e.target.value })}
              />
            </div>
            :
            <div>
              <ReactMarkdown components={components}>{reportText}</ReactMarkdown>
            </div>
          }
          {editClicked ?
            <div className="flex flex-col gap-1.5">
              <Label>{t("common.comment")}</Label>
              <Textarea
                className='h-28'
                value={staticFields.comment || ''}
                onChange={(e) => handleInputChange({ key: 'comment', value: e.target.value })}
                type='text'
              />
            </div>
            :
            <>
              <div className={`${isDrawer ? "-mx-[24px]" : ""} border-t border-t-1 border-t-[#E5E7EC]`} />
              <div className={`${isDrawer ? "p-0" : "py-6"} whitespace-pre-line`}>
                <p className="text-[#71717a] font-normal text-sm">
                  {t("common.comment")}{" "}
                  <span className="text-black text-sm">
                    {report?.comment || "N/A"}
                  </span>{" "}
                </p>
              </div>
            </>
          }
          {editClicked && (
            <div className="grid grid-cols-2 gap-3 pt-1 mb-5">
              <Button onClick={handleEditCancel} variant='outline'>
                {t("actions.cancel")}
              </Button>
              <Button onClick={!editLoading && handleEditSave} disabled={editLoading}>
                {editLoading ? <Loader variant={'small'}/> : t("actions.save")}
              </Button>
            </div>
          )
          }
        </>
      )}
      {showNotification && (
        <Notification text={"Copied"} color={"bg-[#1F232E]"} />
      )}
    </NoPaddingCardWrapper>
  );
}

export default Report;
