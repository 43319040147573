import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/shadcn-ui/select";
import { useDispatch, useSelector } from 'react-redux';
import { selectLanguage, setLanguage } from '@/redux/UserSlice';
import { setUserLanguage } from '@/utils/HelperFunctions';

function LanguageSwitcher() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const language = useSelector(selectLanguage);
  const [loading, setLoading] = useState(false);

  const handleLanguageChange = async (newLanguage) => {
    if (loading) return;
    setLoading(true);
    try {
      await setUserLanguage(newLanguage);
      i18n.changeLanguage(newLanguage);
      dispatch(setLanguage(newLanguage));
    } catch (error) {
      console.error("Failed to update language:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Select
      value={language}
      onValueChange={handleLanguageChange}
      disabled={loading}
    >
      <SelectTrigger className="">
        <SelectValue placeholder="Select language" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="en">English</SelectItem>
        <SelectItem value="es">Español</SelectItem>
        <SelectItem value="de">Deutsch</SelectItem>
        <SelectItem value="fr">Français</SelectItem>
      </SelectContent>
    </Select>
  );
}

export default LanguageSwitcher;