import { createSlice } from '@reduxjs/toolkit'
import { FetchToolFields, FetchToolInputs, FetchToolOutputs } from './ToolsApis'

const initialState = {
    toolFields: [],
    toolInputs: [],
    toolOutputs: [],
    selectedTool: null,
    loading: {
        tools: false,
        inputs: false,
        outputs: false
    }
}

const toolsSlice = createSlice({
    name: 'toolsSlice',
    initialState,
    reducers: {
        setSelectedTool: (state, action) => {
            state.selectedTool = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(FetchToolFields.pending, (state) => {
            state.loading.tools = true
        })
        builder.addCase(FetchToolFields.fulfilled, (state, action) => {
            state.loading.tools = false
            state.toolFields = action.payload?.tools
        })
        builder.addCase(FetchToolFields.rejected, (state) => {
            state.loading.tools = false
        })
        builder.addCase(FetchToolInputs.pending, (state) => {
            state.loading.inputs = true
        })
        builder.addCase(FetchToolInputs.fulfilled, (state, action) => {
            state.loading.inputs = false
            state.toolInputs = action.payload?.tool_inputs
        })
        builder.addCase(FetchToolInputs.rejected, (state) => {
            state.loading.inputs = false
        })
        builder.addCase(FetchToolOutputs.pending, (state) => {
            state.loading.outputs = true
        })
        builder.addCase(FetchToolOutputs.fulfilled, (state, action) => {
            state.loading.outputs = false
            state.toolOutputs = action.payload?.tool_outputs
        })
        builder.addCase(FetchToolOutputs.rejected, (state) => {
            state.loading.outputs = false
        })
    }
})

export const { setToolFields, setSelectedTool, setLoading } = toolsSlice.actions;

export const selectToolFields = state => state?.toolsSlice?.toolFields;
export const selectSelectedTool = state => state?.toolsSlice?.selectedTool;
export const selectToolInputs = state => state?.toolsSlice?.toolInputs;
export const selectToolOutputs = state => state?.toolsSlice?.toolOutputs;
export const selectToolLoading = state => state?.toolsSlice?.loading;

export default toolsSlice.reducer;


