import React from 'react'

function Notification({ text, color, system }) {

  if (system) {
    return (
      <div className={`w-full p-5 md:px-4 md:py-[10px] sticky top-0 left-0 z-50 flex justify-center text-center flex-col sm:flex-row gap-2 items-center border-b bg-orange-100 text-orange-600`}>
        {text}
      </div>
    )
  }

  return (
    <div className='flex justify-center '>
      <div className={`fixed bottom-2 ${color} min-w-[10vw] p-3 rounded-xl text-white text-center`}>
        {text}
      </div>
    </div>
  )
}

export default Notification
