export const pageSize = 50

export const upperCase = (text) => {
  if (!text) return ""; 
  let str = text.split(/[\s_-]+/);
  if (str[str.length-1] === 'template') str.pop();
  return str.map(s => s.toUpperCase() === 'AI' ? 'AI' : s.charAt(0).toUpperCase() + s.slice(1)).join(" ")
};

export const modelLangugaes = [
  "zh", "nl", "en", "fi", "fr", "de", "hi", "it", "ja", 
  "ko", "pl", "pt", "ru", "es", "tr", "uk", "vi"
]

export const generalLanguages = [
  "af", "ar", "hy", "az", "be", "bs", "bg", "ca", "zh", "hr", "cs", 
  "da, da-DK", "nl", "en", "et", "fi", "fr", "gl", "de", "german (switzerland)", 
  "el", "he", "hi", "hu", "is", "id", "it", "ja", "kn", "kk", "ko", 
  "lv", "lt", "mk", "ms", "mi", "mr", "ne", "no", "fa", "pl", "pt", 
  "ro", "ru", "sr", "sk", "sl", "es", "sw", "sv", "tl", "ta", "th", 
  "tr", "uk", "ur", "vi", "cy"
];


export const allModelsLanguageList = [
  { name: "Chinese", value: "zh" },
  { name: "Dutch", value: "nl" },
  { name: "English", value: "en" },
  { name: "Finnish", value: "fi" },
  { name: "French", value: "fr" },
  { name: "German", value: "de" },
  { name: "Hindi", value: "hi" },
  { name: "Italian", value: "it" },
  { name: "Japanese", value: "ja" },
  { name: "Korean", value: "ko" },
  { name: "Polish", value: "pl" },
  { name: "Portuguese", value: "pt" },
  { name: "Russian", value: "ru" },
  { name: "Spanish", value: "es" },
  { name: "Turkish", value: "tr" },
  { name: "Ukrainian", value: "uk" },
  { name: "Vietnamese", value: "vi" },
];

export const languageList = [
  { name: "Afrikaans", value: "af" },
  { name: "Arabic", value: "ar" },
  { name: "Armenian", value: "hy" },
  { name: "Azerbaijani", value: "az" },
  { name: "Belarusian", value: "be" },
  { name: "Bosnian", value: "bs" },
  { name: "Bulgarian", value: "bg" },
  { name: "Catalan", value: "ca" },
  { name: "Chinese", value: "zh" },
  { name: "Croatian", value: "hr" },
  { name: "Czech", value: "cs" },
  { name: "Danish", value: "da, da-DK" },
  { name: "Dutch", value: "nl" },
  { name: "English", value: "en" },
  { name: "Estonian", value: "et" },
  { name: "Finnish", value: "fi" },
  { name: "French", value: "fr" },
  { name: "Galician", value: "gl" },
  { name: "German", value: "de" },
  { name: "German (Switzerland)", value: "german (switzerland)" },
  { name: "Greek", value: "el" },
  { name: "Hebrew", value: "he" },
  { name: "Hindi", value: "hi" },
  { name: "Hungarian", value: "hu" },
  { name: "Icelandic", value: "is" },
  { name: "Indonesian", value: "id" },
  { name: "Italian", value: "it" },
  { name: "Japanese", value: "ja" },
  { name: "Kannada", value: "kn" },
  { name: "Kazakh", value: "kk" },
  { name: "Korean", value: "ko" },
  { name: "Latvian", value: "lv" },
  { name: "Lithuanian", value: "lt" },
  { name: "Macedonian", value: "mk" },
  { name: "Malay", value: "ms" },
  { name: "Maori", value: "mi" },
  { name: "Marathi", value: "mr" },
  { name: "Nepali", value: "ne" },
  { name: "Norwegian", value: "no" },
  { name: "Persian", value: "fa" },
  { name: "Polish", value: "pl" },
  { name: "Portuguese", value: "pt" },
  { name: "Romanian", value: "ro" },
  { name: "Russian", value: "ru" },
  { name: "Serbian", value: "sr" },
  { name: "Slovak", value: "sk" },
  { name: "Slovenian", value: "sl" },
  { name: "Spanish", value: "es" },
  { name: "Swahili", value: "sw" },
  { name: "Swedish", value: "sv" },
  { name: "Tagalog", value: "tl" },
  { name: "Tamil", value: "ta" },
  { name: "Thai", value: "th" },
  { name: "Turkish", value: "tr" },
  { name: "Ukrainian", value: "uk" },
  { name: "Urdu", value: "ur" },
  { name: "Vietnamese", value: "vi" },
  { name: "Welsh", value: "cy" }
];
