import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import UserIcon from '../images/user-icon.png'
import { Link, useNavigate } from 'react-router-dom';
import { Button } from "@/components/shadcn-ui/button";
import { Label } from "@/components/shadcn-ui/label";
import { Input } from "@/components/shadcn-ui/input";
import { getCurrentUser, signIn, confirmSignIn } from 'aws-amplify/auth';
import { AuthWrapper } from '@/components/ui/CardWrapper';
import { useTranslation } from 'react-i18next';

function Login() {
  const { t } = useTranslation();
  
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const handleSignIn = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      const { nextStep } = await signIn({ username: email, password: password });
      // if (!isSignedIn) {
      //   throw Error("The login details are incorrect. Please try again.")
      // }
      switch (nextStep.signInStep) {
        case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED':
          await confirmSignIn({
            challengeResponse: password,
          });
          break;
        default:
          break;
      }
      const { userId } = await getCurrentUser();
      localStorage.setItem("user_id", userId)
      localStorage.setItem("email", email)
      localStorage.removeItem('session_expired');
      navigate("/")
   
    } catch (error) {
      toast.error(`${t('auth.invalidLoginDetails')}`)
      console.error("Error signing in:", error.message);
    } finally {
      setLoading(false)
    }
  };


  useEffect(() => {
    const checkSession = async () => {
      try {
        const localId = localStorage.getItem("user_id")
        if (localId) {
          const { userId } = await getCurrentUser();
          console.log("Local ID is ", localId);
          if (userId === localId) {
            navigate("/")
          }
        }
      } catch (error) {
          console.log("Inside the error ", error);
      }
    }
    checkSession();
  }, [navigate])

  const disabled = loading || !email || !password;

  return (
    <AuthWrapper icon={UserIcon} heading={t('auth.signInHeading')}>
      <form className='text-left w-full' onSubmit={handleSignIn}>
        <div className="grid w-full items-center gap-4 text-left mt-5">
          <div className="flex flex-col space-y-1.5">
            <Label htmlFor="email" className="font-semibold">{t('common.email')}</Label>
            <Input
              required
              id="email"
              name="email"
              value={email}
              onChange={(e) => { setEmail(e.target.value) }}
              type="email"
              placeholder={t('auth.enterEmail')}
              className="text-[#868C98] font-normal "
            />
          </div>
          <div className="flex flex-col space-y-1.5">
            <Label htmlFor="password" className="font-semibold">{t('common.password')}</Label>
            <Input
              required
              id="password"
              name="password"
              value={password}
              onChange={(e) => { setPassword(e.target.value) }}
              type="password"
              placeholder={t('auth.enterPassword')}
              className="text-[#868C98] font-normal "
            />
          </div>
          <div className='flex flex-col gap-2'>
            <Link className='text-sm text-black underline underline-offset-2 mb-2' to='/reset-password'>{t('auth.forgotPasswordLink')}</Link>
            <Button className='font-medium w-full ' variant='default' type='submit' disabled={disabled}>{t('auth.signInButton')}</Button>
            <div className=' text-[#09090B] font-medium text-sm flex justify-center gap-2'>
              <p>{t('auth.dontHaveAccount')}</p><Link className='underline underline-offset-2 text-black font-semibold' to='/sign-up'>{t('auth.signUpButton')}</Link>
            </div>
          </div>
        </div>
      </form>
    </AuthWrapper>
  )
}

export default Login
