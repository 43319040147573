import React, { useState } from "react";
import {
  Card,
  CardContent,
} from "@/components/shadcn-ui/card";
import { Dialog, DialogContent, DialogTrigger } from "@/components/shadcn-ui/dialog";
import { Button } from "@/components/shadcn-ui/button";
import { Label } from "@/components/shadcn-ui/label";
import { Input } from "@/components/shadcn-ui/input";
import { Textarea } from "@/components/shadcn-ui/textarea";
import { Save, Trash2 } from "lucide-react";
import AddSubstitute from "@/components/AddSubstitute";
import Loader from "./Loader";
import { deleteSubstitute, updateSubstitute } from "@/utils/HelperFunctions";
import { removeSubstitute, updateExistingSubstitute } from "@/redux/ProfileSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

export const AddButton = ({ 
  label, 
  type, 
  isOpen, 
  setIsOpen, 
  className = "",
  buttonClassName = "" 
}) => (
  <Dialog className={className} open={isOpen} onOpenChange={setIsOpen}>
    <DialogTrigger className={className}>
      <Button className={`w-full ${buttonClassName}`}>
        {label}
      </Button>
    </DialogTrigger>
    <DialogContent className="rounded-3xl px-8 py-4">
      <AddSubstitute type={type} setIsOpen={setIsOpen} title={label}/>
    </DialogContent>
  </Dialog>
);

const EditableRow = ({ item, mode }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [find, setFind] = useState(item.find);
  const [replace, setReplace] = useState(item.replace);
  const [saving, setSaving] = useState(false);

  const hasChanged = find?.trim() !== item.find || replace?.trim() !== item.replace;

  const handleSave = async () => {
    setSaving(true);
    try {
      const updatedItem = {
        ...item,
        find,
        replace
      };
      const response = await updateSubstitute(updatedItem);
      if (response) {
        dispatch(updateExistingSubstitute(response));
      }
    } catch (error) {
      console.error("Error updating substitute:", error);
    } finally {
      setSaving(false);
    }
  };

  const renderInputs = () => {
    if (mode === "vocabulary") {
      return (
        <div className="w-full">
          <div className="flex gap-2 justify-center items-center h-16 border p-3 border-b-0 rounded-t-lg">
            <Label className="text-gray-900 font-semibold text-sm min-w-[65px]">{t('profile.find')}</Label>
            <Input 
              type="text" 
              value={find} 
              onChange={(e) => setFind(e.target.value)}
              className="w-full font-semibold bg-opacity-40 overflow-y-scroll h-full text-gray-600"
            />
          </div>
          <div className="flex gap-2 items-center h-16 border p-3 rounded-b-lg">
            <Label className="text-gray-900 font-semibold text-sm min-w-[65px]">{t('profile.replace')}</Label>
            <Input 
              type="text" 
              value={replace} 
              onChange={(e) => setReplace(e.target.value)}
              className="w-full font-semibold bg-opacity-40 overflow-y-scroll h-full text-gray-600"
            />
          </div>
        </div>
      );
    }

    return (
      <div className="w-full">
        <div className="flex gap-2 justify-center items-center h-16 border p-3 border-b-0 rounded-t-lg">
            <Label className="text-gray-900 font-semibold text-sm">{t('profile.find')}</Label>
            <Input 
              type="text" 
              value={find} 
              onChange={(e) => setFind(e.target.value)}
              className="w-full font-semibold bg-opacity-40 overflow-y-scroll h-full text-gray-600"
            />
         </div>
        <div className="flex flex-col gap-2 rounded-b-lg h-[170px] w-full border border-slate-200 bg-white p-3">
          <Label className="font-semibold text-gray-900">{t('profile.replace')}</Label>
          <Textarea 
            value={replace} 
            onChange={(e) => setReplace(e.target.value)}
            className="h-full overflow-y-auto font-semibold text-gray-600"
          />
        </div>
      </div>
    );
  };

  return (
    <div className="flex items-start justify-between gap-4">
      <div className="w-full">
        {renderInputs()}
      </div>
      <div className="flex flex-col gap-4">
        <DeleteButton id={item.id} />
        {hasChanged && (
          <Button
            variant="outline"
            size="icon"
            onClick={handleSave}
            disabled={saving}
            className={`${hasChanged ? '' : 'opacity-50 cursor-not-allowed'}`}
          >
            {saving ? (
              <Loader variant='small'/>
            ) : (
              <Save className="w-4 h-4" />
            )}
          </Button>
        )}
      </div>
    </div>
  );
};

export const FindReplaceDesktop = ({ 
  items, 
  mode = "vocabulary"
}) => {
  return (
    <CardContent className="md:block hidden p-0">
      <Card className="rounded-2xl shadow-none border-none">
        {items.map((item, index) => (
          <CardContent
            key={item.id}
            className={`${
              index === items.length - 1 ? "p-4" : "p-4 pb-0"
            }`}
          >
            <EditableRow item={item} mode={mode} />
          </CardContent>
        ))}
        <EmptyState items={items} mode={mode} view="desktop" />
      </Card>
    </CardContent>
  );
};

export const FindReplaceMobile = ({ 
  items, 
  mode = "vocabulary", 
  isDialogOpen, 
  setIsDialogOpen 
}) => {
  const { t } = useTranslation();
  return (
    <CardContent className="md:hidden p-0 border-none">
      <Card className="rounded-2xl shadow-none border-none">
        <CardContent className="py-4 px-0 w-full">
          <div className="w-full">
            <AddButton
              label={mode === "vocabulary" ? t("profile.vocabulary.addButton") : t("profile.textModules.addButton")}
              type={mode === "vocabulary" ? "vocabulary" : "text_module"}
              isOpen={isDialogOpen}
              setIsOpen={setIsDialogOpen}
              className="flex md:hidden w-full px-4" 
              buttonClassName="w-full mb-4" 
            />
          </div>
          <div className="border-b border-slate-200 w-full" />
          <div className="flex flex-col gap-4 mt-4 px-4">
            {items.map((item) => (
              <div key={item.id}>
                <EditableRow item={item} mode={mode} />
              </div>
            ))}
            <EmptyState items={items} mode={mode} view="mobile" />
          </div>
        </CardContent>
      </Card>
    </CardContent>
  );
};

const DeleteButton = (id) => {
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState(false);

  const handleDelete = async () => {
    setDeleting(true);
    const response = await deleteSubstitute(id);
    if (response) {
      dispatch(removeSubstitute(response.id));
    }
    setDeleting(false);
  };

  return (
    <Button
      variant="outline"
      size="icon"
      onClick={handleDelete}
      disabled={deleting}
    >
      {
        deleting ? 
          <Loader variant='small'/> 
        : 
        <Trash2 className="w-4 h-4" />
      }

    </Button>
  );
}

const EmptyState = ({ items, mode, view }) => {
  const { t } = useTranslation();
  if (items.length > 0) return null;

  const message = mode === "vocabulary" ? t("profile.noVocabulary") : t("profile.noTextModules");
  const className = `p-4 text-center ${view === "mobile" ? "text-sm" : "text-xl"} font-medium text-[#71717A] flex items-center ${view === "mobile" ? "h-96" : "h-48"} justify-center`;

  return <p className={className}>{message}</p>;
};