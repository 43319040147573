import React, { useState } from "react";
import { CardContent, Card } from "@/components/shadcn-ui/card";
import { useSelector } from "react-redux";
import { selectSubstitutes } from "@/redux/ProfileSlice";
import { ProfileWrapper } from "../ui/CardWrapper";
import { 
  AddButton, 
  FindReplaceDesktop, 
  FindReplaceMobile 
} from '@/components/Substitutes';
import { useTranslation } from "react-i18next";

const Vocabulary = () => {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const substitutes = useSelector(selectSubstitutes) || [];
  const vocabulary = substitutes.filter((item) => item.type === "vocabulary").sort((a, b) => a.find.localeCompare(b.find));
  
  return (
    <ProfileWrapper
      title={t("profile.tabs.vocabulary")}
      description={t("profile.vocabulary.description")}
      hasButton={true}
      buttonContent={
        <AddButton
          label={t("profile.vocabulary.addButton")}
          type="vocabulary"
          isOpen={isDialogOpen}
          setIsOpen={setIsDialogOpen}
          className="md:flex hidden" 
          buttonClassName="font-medium"
        />
      }
    >
      <CardContent className="p-0">
        <Card className="w-full rounded-2xl">
          <FindReplaceDesktop 
            items={vocabulary} 
            mode="vocabulary" 
          />
          <FindReplaceMobile
            items={vocabulary}
            mode="vocabulary"
            isDialogOpen={isDialogOpen}
            setIsDialogOpen={setIsDialogOpen}
          />
        </Card>
      </CardContent>
    </ProfileWrapper>
  );
};

export default Vocabulary;