import React, { useEffect, useRef,useCallback, useState } from 'react';
import UpoloadFile from '../images/upload.png';
import CheckCircle from '../images/check-circle.png'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from './ui/Button'
import { selectFileUploading, selectIntegration, setFileUploading, setIntegration, setSelectedReport } from '../redux/ReportsSlice';
import { GradientBorder } from './ui/GradientBorder';
import GeneratingIcon from '../images/generating.gif'
import Loader from './Loader';
import { toast } from 'react-toastify'
import { createRecord, handleUpdateStatus, handleUpdateError, getSignedUrl, uploadToS3, getReport, checkIfOnline, getFreshToken } from '../utils/HelperFunctions';
import {CardWrapper} from './ui/CardWrapper';
import RecordForm from './RecordForm';
import { Tooltip } from 'react-tooltip';
import succeedIcon from '../images/SVG/succeedIcon.svg'
import { useCurrentTool } from '@/utils/ToolHelpers';
import { handleLogout } from '@/utils/authSession';
import { useTranslation } from 'react-i18next';

const UploadFile = ({isDisabled, setHidingtabs, toolId }) => {
  
  const { t } = useTranslation();
  const { generation_name } = useCurrentTool();

  const payload = useSelector((state) => state?.reportsSlice?.latestPayload)
  const uploading = useSelector(selectFileUploading)
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  // eslint-disable-next-line
  const [AudioDuration, setAudioDuration] = useState(0)
  const [uploadModelOpen, setUploadModelOpen] = useState(false)
  const [generateReport, setGenerateReport] = useState('')
  const [generating, setGenerating] = useState(false)
  const [tempReport, setTempReport] = useState({})
  const reportIdRef = useRef(null)
  const integration = useSelector(selectIntegration)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [documentInfo, setDocumentInfo] = useState({
    documentId: '',
    rowNumber: 0,
    signedUrl: '',
    audioUploaded: false,
    statusUpdated: false,
  });

  const handleResetState = useCallback(() => {
    setSelectedFile(null);
    setAudioDuration(0);
    setUploadModelOpen(false);
    setGenerateReport('');
    setTempReport({});
    setGenerating(false);
    setHidingtabs(false)
    dispatch(setFileUploading(false));
    reportIdRef.current = null
  }, [dispatch, setHidingtabs]);

  useEffect(() => {
    const fileInput = fileInputRef.current;
    if (!fileInput) return;
    const handleFocus = () => {
      if (fileInput.value.length === 0) {
        setUploadModelOpen(false);
      }
    };
    const initialize = () => {
      window.addEventListener('focus', handleFocus, { once: true });
    };
    fileInput.addEventListener('click', initialize);
    return () => {
      fileInput.removeEventListener('click', initialize);
      window.removeEventListener('focus', handleFocus);
    };
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setHidingtabs(true)
    setUploadModelOpen(false)
    const audio = new Audio();
    audio.src = URL.createObjectURL(file);
    audio?.addEventListener('loadedmetadata', () => {
      setAudioDuration(audio.duration);
    });
  };

  const genertae=()=>{
    setSelectedFile("");
  }

  // const formatTime = (seconds) => {
  //   const hours = Math.floor(seconds / 3600);
  //   const minutes = Math.floor((seconds % 3600) / 60);
  //   const remainingSeconds = Math.floor(seconds % 60);
  //   const timeString = `${hours}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;

  //   return timeString.toLocaleString('en-US');
  // };


  const handleDrop = (event) => {
    if (disabled) return;
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setSelectedFile(file);
    const audio = new Audio();
    audio.src = URL.createObjectURL(file);
    audio.addEventListener('loadedmetadata', () => {
      setAudioDuration(audio.duration);
    });
  };

  const handleGoToReport = () => {
    navigate(`/scribe/${documentInfo.rowNumber}`)
  }

  const handleDragOver = (event) => {
    if (disabled) return;
    event.preventDefault();
  };

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  // const handleGenerateNewReport = (e) => {
  //   e.preventDefault()
  //   handleGenerateReport({ patientName: name, doctorName: doctor, clinicName: clinic, selectedFile: selectedFile, audioLanguage, reportLanguage: reportLanguage, comment, template: template })
  //   setGenerateReport('generating')
  // }

  useEffect(() => {
    if (payload?.id === reportIdRef.current) {
      if (payload.report_fetched) {
        setTempReport(payload);
      } else if (payload.status === "completed") {
        handleResetState();
      }
    }
    if (tempReport?.report?.length > 0 && generateReport === 'generating' && generating && !uploading) {
      setGenerating(false);
      setGenerateReport('upload-report-generated');
      dispatch(setSelectedReport(tempReport));
    }
  }, [payload, handleResetState, tempReport, dispatch, generateReport, generating, uploading]);


  const retrySubmit = () => {
    setGenerateReport('');
    dispatch(setFileUploading(false));
    setGenerating(false);
    toast.error(t("form.errors.networkError"));
  }

  const clearGenerateStates = () => {
    setDocumentInfo({
      documentId: '',
      signedUrl: '',
      audioUploaded: false,
      statusUpdated: false,
    });
  };

  const handleGenerateReport = async (data) => {
    const { toolFieldsData, reportLanguage, audioLanguage, comment, template, uses_custom_template, urgent, enableTextModules } = data;
    const file = data.recordedAudioBlob || selectedFile;
  
    console.log('File to upload:', file); // Add this log
    dispatch(setFileUploading(true));
  
    if (!file) {
      toast.error('No file selected for upload.');
      return;
    }
  
    try {
  
      const userId = localStorage.getItem('user_id');
  
      const allowedAudioTypes = [
        'audio/flac',
        'audio/mp4',
        'video/mp4',
        'audio/mpeg',
        'audio/ogg',
        'audio/wav',
        'video/webm',
        'audio/webm',
        'audio/x-m4a',
        'audio/x-wav',
      ];

      if (file.size / (1024 * 1024) > 250.0) {
        toast.error('The audio file is too large. The maximum size is 250 MB.');
        handleResetState();
        return;
      }
      if (!allowedAudioTypes.includes(file.type)) {
        toast.error('Please upload an audio file.');
        handleResetState();
        return;
      }
  
      toolFieldsData.forEach((field, index) => {
        if(index > 0)
          localStorage.setItem(field.name, field.value)
      })
      localStorage.setItem('audio_language', audioLanguage);
      localStorage.setItem('report_language', reportLanguage);
  
      let { documentId, rowNumber, signedUrl, audioUploaded, statusUpdated } = documentInfo;

      if (!documentId) {
        const document = await createRecord({ userId, toolFieldsData, reportLanguage, uses_custom_template, audioLanguage, comment, template, meta_type: file.type, fileSize: file.size, toolId, urgent, integration, enableTextModules });
        documentId = document?.documentId;
        rowNumber = document?.row_number;
        if (!documentId) {
          retrySubmit();
          return;
        }
        setDocumentInfo((prevState) => ({ ...prevState, documentId, rowNumber }));
      }

      if (!signedUrl) {
        signedUrl = await getSignedUrl({ id: documentId, name: documentId, type: "upload" });
        if (!signedUrl) {
          retrySubmit();
          return;
        }
        setDocumentInfo((prevState) => ({ ...prevState, signedUrl }));
      }

      if (!audioUploaded) {
        const [uploadStatus, isOnline] = await Promise.all([
          uploadToS3({ id: documentId, signedUrl, file }),
          checkIfOnline()
        ]);
      
        if (!isOnline || !uploadStatus) {
          retrySubmit();
          return;
        }
        setDocumentInfo((prevState) => ({ ...prevState, audioUploaded: true }));
      }
      
      dispatch(setFileUploading(false));
      setGenerating(true);
      dispatch(setIntegration('reset'))

      if (!statusUpdated) {
        const recordUpdateStatus = await handleUpdateStatus({ status: 'processing', documentId });
        if (!recordUpdateStatus) {
          retrySubmit();
          return;
        }
        setDocumentInfo((prevState) => ({ ...prevState, statusUpdated: true }));
      }

      let token
      try {
        token = await getFreshToken()
      } catch (error) {
        retrySubmit();
        return;
      }
      const { response, responseData } = await getReport(documentId, file.size / (1024 * 1024), token);

      if (responseData.statusCode !== 200) {
        if (response.status === 401) {
          handleUpdateError({ id: documentId, error: 'Session expired.' });
          handleLogout();
          toast.error('Your session has expired. Please login and try again.');
          return;
        } else {
          clearGenerateStates();
          handleResetState();
          handleUpdateError({ id: documentId, reportError: JSON.stringify(responseData) });
          return;
        }
      }
      reportIdRef.current = documentId;
      clearGenerateStates();
    } catch (error) {
      console.log('API error ', error);
      if (JSON.stringify(error) === '{}' || !error) {
        handleUpdateError({ error: 'Failed to make request to backend server', id: documentInfo.documentId });
      } else {
        handleUpdateError({ error: JSON.stringify(error), id: documentInfo.documentId });
      }
      clearGenerateStates();
      handleResetState();
    }
  };

  const disabled = isDisabled.loading || isDisabled.noWords;

  return (
    <div>
      {
        !selectedFile && generateReport === '' && (
          <>
            {isDisabled.noWords && <Tooltip id="no-words" place="bottom"/>}
            <CardWrapper className='border-none'>
              <div
                className={`flex flex-col items-center justify-center w-full border border-[#E4E4E7] p-5 rounded-xl ${disabled && 'cursor-not-allowed'}`}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onClick={!disabled ? handleBrowseClick : undefined}
                data-tooltip-id='no-words' data-tooltip-content='No more words left. Please contact MPAssist.'
              >
                <GradientBorder>
                  <div className="shadow-sm bg-white rounded-full px-4 py-4">
                    {uploadModelOpen ? (
                      <img width={'24px'} src={GeneratingIcon} alt='recording mic ' loading='eager'  />
                    ):(
                      <img width={'24px'} src={UpoloadFile} alt='recording mic ' />
                    )}
                  </div>
                </GradientBorder>
                {uploadModelOpen ? (
                  <>
                    <h1 className='md:text-[24px] text-xl sm:w-[320px] max-w-[320px] font-medium mt-[16px]'>{t('reports.generate.file.selecting')}</h1>
                    <p className='mt-[4px] sm:w-80 font-SuisseIntlLight font-normal text-[16px] text-[#505050]'>{t('reports.generate.file.wait')}</p>
                  </>
                ) : (
                  <>
                    <h1 className='md:text-[24px] text-xl font-medium mt-[16px]'>{t('reports.generate.file.choose')}</h1>
                      <p className='mt-[4px] sm:w-[320px] max-w-[320px] text-[16px] font-SuisseIntlLight font-normal text-[#505050]'>{t('reports.generate.file.formats')}</p>
                  </>
                )}
                <input
                  type="file"
                  onClick={() => { setUploadModelOpen(true) }}
                  onChange={handleFileChange}
                  className="hidden"
                  ref={fileInputRef}
                  id="fileInput"
                  accept="audio/*, video/mp4, video/webm"
                />
                <label htmlFor="fileInput">
                  <Button className={`mt-[16px] ${disabled && 'cursor-not-allowed opacity-80'}`} >
                  {t('reports.generate.file.browse')}
                  </Button>
                </label>
              </div>
            </CardWrapper>
          </>
        )
      }
      {
        selectedFile && generateReport === '' && (
          <CardWrapper className='border-none'>
            <div className='flex flex-col justify-center items-center w-full'>
            <GradientBorder >
              <div className="shadow-sm bg-white rounded-full p-3">
                <img className='max-w-none w-4 h-4' src={succeedIcon} alt='recording mic ' />
              </div>
            </GradientBorder>
            <h1 className='font-bold mt-[16px] font-SuisseIntlLight text-[24px]'>{t('reports.generate.file.selected')}</h1>
            <p className='sm:w-[320px] max-w-[320px] mt-[4px] mb-[12px] text-[#505050] text-[16px] font-SuisseIntlLight font-normal'>{t('reports.generate.fillForm', { type: generation_name.toLowerCase() })}</p>
              <RecordForm GenerateReport={setGenerateReport} HandleResetState={handleResetState} UploadNewRecording={genertae} handleGenerateReport={handleGenerateReport} recordedAudioBlob={selectedFile} />
                </div>
          </CardWrapper>
        )
      }

      {
        generateReport === 'generating' && (
          <CardWrapper className='border-none'>
          <div className='flex flex-col h-[96dvh] md:h-fit justify-center items-center w-full'>
              <GradientBorder>
                <div className="shadow-sm bg-white rounded-full px-4 py-4">
                  <Loader />
                </div>
              </GradientBorder>
              {
                uploading && (
                  <div className='flex flex-col items-center w-full'>
                    <>
                      <h1 className='font-medium mt-[16px] md:text-[24px] text-xl'>{t('reports.generate.uploading')}</h1>
                      <p className='sm:w-[320px] max-w-[320px] text-[#505050] mt-[4px] font-SuisseIntlLight font-medium text-[16px]'>{t('reports.generate.pleaseWait')}</p>
                    </>
                    <Button variant='disabled' className='mt-[16px] max-w-fit cursor-not-allowed' onClick={()=>{}}>
                      {t('reports.generate.new', { type: generation_name })}
                    </Button>
                  </div>
                ) 
              }
              {
                generating && (
                  <div className='flex flex-col items-center w-full'>
                    <>
                      <h1 className='font-medium mt-[16px] md:text-[24px] text-xl'>{t('reports.generate.generating')}</h1>
                      <p className='sm:w-[320px] max-w-[320px] text-[#505050] mt-[4px] font-SuisseIntlLight font-normal text-[16px]'>{t('reports.generate.waitMinutes')}</p>
                    </>
                    <Button variant='light' className='mt-[16px] max-w-fit' onClick={handleResetState}>
                      {t('reports.generate.new', { type: generation_name })}
                    </Button>
                  </div>
                )
              }   
            </div>
          </CardWrapper>
        )
      }
      {
        generateReport === 'upload-report-generated' && (
          <div className='flex flex-col h-[96dvh] md:h-fit justify-center items-center w-full'>
              <GradientBorder variant={2}>
                <div className="shadow-sm bg-white rounded-full px-4 py-4">
                  <img className='w-4 h-4' src={CheckCircle} alt='recording mic ' />
                </div>
              </GradientBorder>
              <h1 className='font-medium mt-[16px] text-[24px]'>{t('reports.generate.hasBeenGenerated', { type: generation_name })}</h1>
              <p className='sm:w-[320px] max-w-[320px] mt-[4px] text-[#505050] font-SuisseIntlLight font-normal text-[16px]'>{t('reports.generate.reviewButton')}</p>
              <div>
                <Button variant='light' className='mt-[16px]' onClick={handleGoToReport}>
                  {t('reports.generate.view', { type: generation_name })}
                </Button>
              </div>
          </div>
        )
      }
    </div >
  );
};

export default UploadFile;