import React, { useState } from "react";
import { CardContent, Card } from "@/components/shadcn-ui/card";
import { useSelector } from "react-redux";
import { selectSubstitutes } from "@/redux/ProfileSlice";
import { ProfileWrapper } from "../ui/CardWrapper";
import { 
  AddButton, 
  FindReplaceDesktop, 
  FindReplaceMobile 
} from '@/components/Substitutes';
import { useTranslation } from "react-i18next";

const TextModules = () => {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const substitutes = useSelector(selectSubstitutes) || [];
  const textModules = substitutes.filter((item) => item.type === "text_module").sort((a, b) => a.find.localeCompare(b.find));

  return (
    <ProfileWrapper
      title={t("profile.tabs.textModules")}
      description={t("profile.textModules.description")}
      hasButton={true}
      buttonContent={
        <AddButton
          label={t("profile.textModules.addButton")}
          type="text_module"
          isOpen={isDialogOpen}
          setIsOpen={setIsDialogOpen}
          className="md:flex hidden" 
          buttonClassName="font-medium"
        />
      }
    >
      <CardContent className="p-0">
        <Card className="w-full rounded-2xl">
          <FindReplaceDesktop 
            items={textModules} 
            mode="text_module" 
          />
          <FindReplaceMobile
            items={textModules}
            mode="text_module"
            isDialogOpen={isDialogOpen}
            setIsDialogOpen={setIsDialogOpen}
          />
        </Card>
      </CardContent>
    </ProfileWrapper>
  );
};

export default TextModules;
