import { createSlice } from '@reduxjs/toolkit'
import { FetchBackendReports, FetchReport, FetchReportTemplates, UpdateReportStatus } from './ReportsApis'
import { pageSize } from '../utils/constants';
import { shouldAddReportBasedOnFilters } from '@/lib/utils';

const integrationState = {
  exists: false,
  path: null,
  client: null,
  patientId: null,
  patientIdSystem: null,
  template: null,
  fullName: null,
}

const initialState = {
  loading: false,
  reports: [],
  reportTemplates: [],
  fileUploading: false,
  allReports: {
    reports: [],
    totalPages: 0,
    totalCount: 0
  },
  processingReports: {
    reports: [],
    totalPages: 0,
    totalCount: 0
  },
  inProgressReports: {
    reports: [],
    totalPages: 0,
    totalCount: 0
  },
  completedReports: {
    reports: [],
    totalPages: 0,
    totalCount: 0
  },
  generatedReports: {
    reports: [],
    totalPages: 0,
    totalCount: 0
  },
  urgentReports: {
    reports: [],
    totalPages: 0,
    totalCount: 0
  },
  selectedReport: {},
  totalReports: 0,
  totalPages: 0,
  latestPayload: null,
  error: '',
  updating: false,
  recordingStarted: false,
  recordingDuration: 0,
  reportId: null,
  integration: integrationState,
}

const getReportState = (state, tabType) => {
  const reportStateMap = {
    'all': state.allReports,
    'processing': state.processingReports,
    'generated': state.generatedReports,
    'in-progress': state.inProgressReports,
    'completed': state.completedReports,
    'urgent': state.urgentReports
  };
  return reportStateMap[tabType];
}

const findAndReplaceReport = (reports, report) => {
  for (let index = 0; index < reports.length; index++) {
    if (reports[index].id === report.id) {
      reports[index] = report
      break;
    }
  }
}

export const reportSlice = createSlice({
  name: 'reportsSlice',
  initialState,
  reducers: {
    setReportUpdate: (state, action) => {
      state.updating = action.payload
    },
    setFileUploading: (state, action) => {
      state.fileUploading = action.payload
    },
    setSelectedReport: (state,action) => {
      state.selectedReport = {...action.payload}
    },
    setLatestPayload: (state, action) => {
      state.latestPayload = action.payload
    },
    setRecordingStarted: (state, action) => {
      state.recordingStarted = action.payload
    },
    setRecordingDuration: (state, action) => {
      state.recordingDuration = action.payload
    },
    setReportId: (state, action) => {
      state.reportId = action.payload
    },
    setIntegration: (state, action) => {
      if (action.payload === 'reset') {
        state.integration = integrationState
        return
      }
      state.integration = { ...state.integration, ...action.payload }
    },
    UpdateReport: (state, action) => {
      const { report: updatedReport, toolName, currentToolFields } = action.payload; 
      const index = state.allReports.reports.findIndex(report => report.id === updatedReport.id);
      if (index !== -1) {
        state.allReports.reports[index] = updatedReport;
        const processingIndex = state.processingReports.reports.findIndex(report => report.id === updatedReport.id);
        if (processingIndex !== -1) {
          state.processingReports.reports = state.processingReports.reports.filter(report => report.id !== updatedReport.id);
          state.processingReports.totalCount--;
        }
      }
      else {
        const shouldAddReport = shouldAddReportBasedOnFilters(updatedReport, toolName, currentToolFields);
        if (shouldAddReport) {
          state.allReports.reports.unshift(updatedReport);
          state.allReports.totalCount++;
          const reportState = getReportState(state, updatedReport.status);
          reportState.reports.unshift(updatedReport);
          reportState.totalCount++;
        }
      }
    },
    removeReport: (state, action) => {
      const updatedReport = action.payload;
      state.processingReports.reports = state.processingReports.reports.filter(report => report.id !== updatedReport.id);
      state.allReports.reports = state.allReports.reports.filter(report => report.id !== updatedReport.id);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(FetchBackendReports.pending, (state, action) => {
      const tabType = action.meta.arg.status
      const reportState = getReportState(state, tabType);
      reportState.loading = true;
    })
    builder.addCase(FetchBackendReports.fulfilled, (state, action) => {
      const tabType = action.payload.tabType
      const data = { ...action.payload?.data }
      const count = action.payload?.count
      const totalPages = Math.ceil(count / pageSize)
      // console.log('status:', tabType, '- count:', data?.data.length, '- totalCount:', count)
      const reportState = getReportState(state, tabType);
      reportState.reports = tabType === 'completed' ? [...data.data].filter(report => report.report_fetched) : [...data.data];
      reportState.totalCount = tabType === 'completed' ? [...data.data].filter(report => report.report_fetched).length : count;
      reportState.totalPages = totalPages;
      reportState.loading = false;
    })
    builder.addCase(FetchBackendReports.rejected, (state, action) => {
      const tabType = action.meta.arg.status
      const reportState = getReportState(state, tabType);
      reportState.loading = false;
    })
    builder.addCase(FetchReport.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(FetchReport.fulfilled, (state, action) => {
      const { report } = action.payload;  
      if (report) {
        state.selectedReport = { ...report }
        // Following are being set for newly processed reports that might have to be reflected on list page (i.e. on retry):
        findAndReplaceReport(state.allReports.reports, report)
        findAndReplaceReport(state.processingReports.reports, report)
      }
      state.loading = false
    })
    builder.addCase(FetchReport.rejected, (state, action) => {
      state.loading = false
    })
    builder.addCase(UpdateReportStatus.pending, (state, action) => {
      state.loading = false
    })
    builder.addCase(UpdateReportStatus.fulfilled, (state, action) => {
      if (action.payload) {
      const { status, oldStatus, id } = action.payload;
        state.selectedReport = { ...state.selectedReport, status };
        findAndReplaceReport(state.allReports.reports, state.selectedReport);

        const oldReportState = getReportState(state, oldStatus);
        oldReportState.reports = oldReportState.reports.filter(report => report.id !== id);
        oldReportState.totalCount--;

        const reportState = getReportState(state, status);
        reportState.reports.unshift(state.selectedReport);
        reportState.totalCount++;
      }
      state.loading = false
      state.updating = false

    })
    builder.addCase(UpdateReportStatus.rejected, (state, action) => {
      state.loading = false
      state.updating = false
    })
    builder.addCase(FetchReportTemplates.pending, (state, action) => {
      state.loading = false
    })
    builder.addCase(FetchReportTemplates.fulfilled, (state, action) => {
      if (action.payload) {
        state.reportTemplates = action.payload?.templates
      }
      state.loading = false
    })
    builder.addCase(FetchReportTemplates.rejected, (state, action) => {
      state.loading = false
    })
  }
})

export const { setSelectedReport, UpdateReport, removeReport, setLatestPayload, setFileUploading, setReportUpdate, setRecordingStarted, setRecordingDuration, setReportId, setIntegration } = reportSlice.actions

export const selectAllTemplates = (state) => state.reportsSlice?.reportTemplates
export const selectFileUploading = (state) => state.reportsSlice?.fileUploading
export const selectIsDialogOpen = (state) => state.reportsSlice?.isDialogOpen
export const seletecReportLoader = (state) => state.reportsSlice?.loading
export const selectRecordingStarted = (state) => state.reportsSlice?.recordingStarted
export const selectRecordingDuration = (state) => state.reportsSlice?.recordingDuration
export const selectReportId = (state) => state.reportsSlice?.reportId
export const selectIntegration = (state) => state.reportsSlice?.integration

export const selectReports = (state) => {
  return {
    all: state.reportsSlice?.allReports,
    processing: state.reportsSlice?.processingReports,
    generated: state.reportsSlice?.generatedReports,
    "in-progress": state.reportsSlice?.inProgressReports,
    completed: state.reportsSlice?.completedReports,
    urgent: state.reportsSlice?.urgentReports
  }
}
export const selectReportsLoading = (state) => {
  return {
    all: state.reportsSlice?.allReports?.loading,
    processing: state.reportsSlice?.processingReports?.loading,
    generated: state.reportsSlice?.generatedReports?.loading,
    "in-progress": state.reportsSlice?.inProgressReports?.loading,
    completed: state.reportsSlice?.completedReports?.loading,
    urgent: state.reportsSlice?.urgentReports?.loading
  }
}
export const selectReportsTotalCount = (state) => {
  return {
    all: state.reportsSlice?.allReports?.totalCount,
    processing: state.reportsSlice?.processingReports?.totalCount,
    generated: state.reportsSlice?.generatedReports?.totalCount,
    "in-progress": state.reportsSlice?.inProgressReports?.totalCount,
    completed: state.reportsSlice?.completedReports?.totalCount,
    urgent: state.reportsSlice?.urgentReports?.totalCount
  }
}
export default reportSlice.reducer