import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAuthSessionWithLogout } from '../utils/authSession';
// import { toast } from 'react-toastify';

export const FetchMembers = createAsyncThunk('fetchMembers', async (_, { rejectWithValue }) => {
    try {
        const endPoint = process.env.REACT_APP_AWS_API_ENDPOINT;
        const accessToken = await fetchAuthSessionWithLogout();
        if (!accessToken) {
            return
        }
        let response = await fetch(`${endPoint}/get-members/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken.toString()}`,
            },
            body: JSON.stringify({})
        })
        response = await response.json()
        if (response.error) {
            console.log("Error is ", response.error);
            return []
        }
        return response
    } catch (error) {
        console.log("Fetch members error:", error);
        // toast.error("Failed to fetch members. Please try again.");
        return rejectWithValue(error.message);
    }
});

export const FetchSubstitutes = createAsyncThunk('fetchSubstitutes', async (_, {rejectWithValue}) => {
    try {
        const endPoint = process.env.REACT_APP_AWS_API_ENDPOINT;
        const accessToken = await fetchAuthSessionWithLogout();
        if (!accessToken) {
            return
        }
        let response = await fetch(`${endPoint}/get-substitutes/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken.toString()}`,
            },
            body: JSON.stringify({})
        })
        response = await response.json()
        if (response.error) {
            console.log("Error is ", response.error);
            return []
        }
        return response
    } catch (error) {
        console.log("Fetch substitutes error:", error);
        // toast.error("Failed to fetch substitutes. Please try again.");
        return rejectWithValue(error.message);
    }
});

export const FetchTransactions = createAsyncThunk('fetchTransactions', async (_, { rejectWithValue }) => {
    try {
        const endPoint = process.env.REACT_APP_AWS_API_ENDPOINT;
        const accessToken = await fetchAuthSessionWithLogout();
        if (!accessToken) {
            return
        }
        let response = await fetch(`${endPoint}/get-transactions/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken.toString()}`,
            },
            body: JSON.stringify({})
        })
        response = await response.json()
        if (response.error) {
            console.log("Error is ", response.error);
            return []
        }
        return response
    } catch (error) {
        console.log("Fetch transactions error:", error);
        // toast.error("Failed to fetch transactions. Please try again.");
        return rejectWithValue(error.message);
    }
});

export const FetchPlans = createAsyncThunk('fetchPlans', async (_, { rejectWithValue }) => {
    try {
        const endPoint = process.env.REACT_APP_AWS_API_ENDPOINT;
        const accessToken = await fetchAuthSessionWithLogout();
        if (!accessToken) {
            return
        }
        let response = await fetch(`${endPoint}/get-plans/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken.toString()}`,
            },
            body: JSON.stringify({})
        })
        response = await response.json()
        if (response.error) {
            console.log("Error is ", response.error);
            return []
        }
        return response
    } catch (error) {
        console.log("Fetch plans error:", error);
        // toast.error("Failed to fetch plans. Please try again.");
        return rejectWithValue(error.message);
    }
});

export const FetchModels = createAsyncThunk('fetchModels', async (_, { rejectWithValue }) => {
    try {
        const endPoint = process.env.REACT_APP_AWS_API_ENDPOINT;
        const accessToken = await fetchAuthSessionWithLogout();
        if (!accessToken) {
            return
        }
        let response = await fetch(`${endPoint}/get-models/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken.toString()}`,
            },
            body: JSON.stringify({})
        })
        response = await response.json()
        if (response.error) {
            console.log("Error is ", response.error);
            return []
        }
        return response
    } catch (error) {
        console.log("Fetch models error:", error);
        return rejectWithValue(error.message);
    }
});