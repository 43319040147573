import { useEffect, useRef, useState } from 'react'
import { useWavesurfer } from '@wavesurfer/react'
import Loader from './Loader';

const Playback = ({ playbackAudioUrl, muted, playBackState = "pause", onPlaybackStateChange }) => {
  const containerRef = useRef(null)
  const [isLoading, setIsLoading] = useState(true)
  
  const { wavesurfer } = useWavesurfer({
    container: containerRef,
    height: 60,
    waveColor: '#535A63',
    progressColor: '#EE772F',
    barHeight: 1,
    barWidth: 2,
    barRadius: 4,
    barGap: 4,
    url: playbackAudioUrl,
    dragToSeek: true,
    backend: "WebAudio"
  })

  useEffect(() => {
    if (wavesurfer) {
      wavesurfer.on("ready", () => {
        setIsLoading(false);
        
      });

      // Mute audio for the first 2 seconds if duration exceeds playback limit
      wavesurfer.on("play", () => {
        if (muted) {
          wavesurfer.setVolume(0);
          setTimeout(() => {
            wavesurfer.setVolume(1);
          }, 2000);
        }
      });
      
      wavesurfer.on("finish", () => {
        onPlaybackStateChange('pause');
      });

      if (playBackState === "pause" && wavesurfer.isPlaying()) {
        wavesurfer.pause()
      } else if (playBackState === "resume" && !wavesurfer.isPlaying()) {
        wavesurfer.play()
      }
    }
  }, [playBackState, onPlaybackStateChange, muted, wavesurfer]);

  useEffect(() => {
    setIsLoading(true);
  }, [playbackAudioUrl]);

  return (
    <div className="w-full relative">
      <div className="wave-container">
        <div ref={containerRef} className={isLoading ? 'opacity-0' : 'opacity-100 transition-opacity duration-300'} />
      </div>
      
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-50/50">
          <div className="flex flex-col items-center space-y-2">
            <Loader />
          </div>
        </div>
      )}
    </div>
  )
}

export default Playback