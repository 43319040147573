import React, { useEffect, useMemo } from "react";
import { GradientBorder } from "../components/ui/GradientBorder";
import { Link } from "react-router-dom";
import scribeAi from '../images/SVG/scribe.svg'
import consultAi from '../images/SVG/consult.svg'
import meetingAi from '../images/SVG/meeting.svg'
import invoiceAI from '@/images/SVG/invoice.svg'
import therapyAI from '@/images/SVG/therapy.svg'
import careAI from '@/images/SVG/care.svg'
import moreProduct from '../images/SVG/more.svg'
import { useDispatch, useSelector } from "react-redux";
import { setSelectedTool, selectToolLoading, selectToolFields } from '../redux/ToolsSlice';
import { FetchMembers, FetchModels, FetchPlans } from "@/redux/ProfileApis";
import Loader from "@/components/Loader";
import { selectUserData, selectFailed, selectLoading } from "@/redux/UserSlice";
import { FetchUser } from "@/redux/UserApis";
import { handleLogout } from "@/utils/authSession";
import { selectMembers, selectModels, selectPlans, selectProfileLoading } from "@/redux/ProfileSlice";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toolLoading = useSelector(selectToolLoading);
  const profileLoading = useSelector(selectProfileLoading);
  const loading = useMemo(() => ({ ...toolLoading, ...profileLoading }), [toolLoading, profileLoading]);
  
  const userId = localStorage.getItem("user_id");
  const user = useSelector(selectUserData);
  const userLoading = useSelector(selectLoading);
  const userFailed = useSelector(selectFailed);
  
  const tools = useSelector(selectToolFields);
  const members = useSelector(selectMembers)?.length;
  const plans = useSelector(selectPlans)?.length;
  const models = useSelector(selectModels)?.length

  useEffect(() => {
    if (!!userId && userFailed) {
      handleLogout();
    }
  }, [userId, userFailed])
  
  useEffect(() => {
    if (userId && (!user || user.length === 0)) {
      dispatch(FetchUser({ userId }))
    }
  }, [dispatch, userId, user]);
  
  useEffect(() => {
    dispatch(setSelectedTool(''));
    if (user && !userLoading && user.is_admin) {
      if (!members && !loading.members) dispatch(FetchMembers());
      if (!plans && !loading.plans) dispatch(FetchPlans());
      if (!models && !loading.models) dispatch(FetchModels())
    }
  }, [dispatch, tools, members, plans, models, toolLoading, loading, user, userLoading]);

  const getIcon = (toolName) => {
    switch (toolName) {
      case "Scribe AI":
        return scribeAi; 
      case "Consult AI":
        return consultAi;
      case "Meeting AI":
        return meetingAi;
      case "Care AI":
        return careAI;
      case "Invoice AI":
        return invoiceAI;
      case "Therapy AI":
        return therapyAI;
      default:
        return ''
    }
  };

  const handleToolClick = (value) => {
    dispatch(setSelectedTool(value));
  };

  const sortedTools = tools?.slice().sort((a, b) => a.position - b.position);

  return (
    <div className="pb-6">
      {loading?.tools ? (
        <div className="w-full h-screen flex justify-center items-center">
          <Loader/>
        </div>
      ) : (
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 w-[90vw] md:w-[80vw] mt-5">
          {sortedTools?.map((tool, index)=>(
            <Link
              to={!tool?.in_service ? '#' : `/${tool.name.toLowerCase().split(' ')[0]}`}
              onClick={() => {
                if (tool?.in_service) {
                  handleToolClick(tool.name);
                }
              }}
              className={`flex w-full p-4 md:h-64 rounded-3xl bg-white ${
                !tool?.in_service
                  ? 'opacity-50 border-dashed border-2 cursor-default'
                  : 'border border-gray-200'
              } md:flex-col md:justify-center justify-start gap-3 md:gap-0 items-center`}
              key={index}
            >
              <GradientBorder>
                <div className="shadow-sm bg-white rounded-full md:p-4 p-3">
                  <img className="md:w-6 w-5 h-5 md:h-6 max-w-none" src={getIcon(tool.name)} alt={tool.name} />
                </div>
              </GradientBorder>
              <div>
                <h1 className='font-semibold md:text-center md:mt-[16px] font-SuisseIntlLight md:text-[20px] text-[18px]'>{tool?.name || 'N/A'}</h1>
                <p className="mt-[3px] md:text-center text-left text-[#505050] md:text-base text-[14px] font-SuisseIntlLight font-medium">
                  {t(`tools.${tool.name}`)}
                </p>
              </div>
            </Link>
          ))}
          <div className='flex w-full md:h-64 p-4 rounded-3xl bg-white md:flex-col opacity-50 gap-3 md:gap-0 md:justify-center justify-start items-center border-gray-200 border-dashed border-2 cursor-default'>
            <GradientBorder>
              <div className="shadow-sm bg-white rounded-full md:p-4 p-3">
                <img className="md:w-6 w-5 max-w-none" src={moreProduct} alt='more product icon' />
              </div>
            </GradientBorder>
            <div>
              <h1 className='font-semibold md:text-center md:mt-[16px] font-SuisseIntlLight md:text-[20px] text-[18px]'>{t('tools.more')}</h1>
              <p className="mt-[3px] md:text-center text-left text-[#505050] md:text-base text-[14px] font-SuisseIntlLight font-medium">{t('tools.comingSoon')}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
