import React from 'react'
import billingIcon from '../images/SVG/billingIcon.svg'
import TokenTab from './TokenTab'
import { DialogWrapper } from '@/components/ui/CardWrapper'
import { useTranslation } from 'react-i18next'

const AddToken = ({ setIsOpen }) => {
  const { t } = useTranslation();
  
  return (
    <DialogWrapper
      title={t('tokens.add')}
      description={t('tokens.addText')}
      icon={billingIcon}
      setIsOpen={setIsOpen}
    >
      <TokenTab setIsOpen={setIsOpen} />
    </DialogWrapper>
  )
}

export default AddToken